import React from "react";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const FilterItem = (props) => {
  const [checked, setChecked] = useState(false);
  const handleCheckToggle = () => {
    props.onClick(props.item);
    setChecked((checked) => !checked);
  };
  return (
    <button
      onClick={handleCheckToggle}
      className="flex items-center justify-between xl:justify-start w-full xl:w-auto filter-item-wrap"
    >
      <div className="hidden xl:block">
        <Checkbox
          checked={checked}
          {...label}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: 32 },
            color: "#BDBCDB",
            "&.Mui-checked": {
              color: "#213669",
            },
          }}
        />
      </div>
      <p className="filter-label">{props.item.name} {props.item.surname}</p>
      <div className="block xl:hidden">
        <Checkbox
          checked={checked}
          {...label}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: 32 },
            color: "#BDBCDB",
            "&.Mui-checked": {
              color: "#213669",
            },
          }}
        />
      </div>
    </button>
  );
};
