import { Routes, Route } from "react-router-dom";
import { Layout } from "pages/Layout.jsx";
import { Main } from "pages/Main.jsx";
import { Catalog } from "pages/Catalolg";
import { Book } from "pages/Book";
import { Reader } from "components/Reader";
import { BookReader } from "pages/BookReader";
import { Test } from "pages/Test";
import { Autor } from "pages/Autor";
import { Profile } from "pages/Profile";
import { PrivateWrapper } from "components/PrivateWrapperr";
import { NotFound } from "pages/NotFound";
import { VK } from "pages/Vk";
import { About } from "pages/About";
import { EmailConfirmation } from "pages/EmailConfirmation";

export default function AppNavigation() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} ErrorBoundary={<NotFound />}>
        <Route index element={<Main />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/about" element={<About />} />

        <Route path="/catalog/:id" element={<Book />} />
        <Route path="/autor" element={<Autor />} />
        <Route element={<PrivateWrapper />}>
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/confirm-email" element={<EmailConfirmation />} />
      </Route>
      <Route path="/reader" element={<BookReader />} />
      <Route path="/vk" element={<VK />} />

      <Route path="/test" element={<Test />} />
    </Routes>
  );
}
