import React from "react";
import { Link } from "react-router-dom";

export const YellowButton = (props) => {
  return (
    <Link to={props.to}>
      <div className=" default-button yellow flex  xl:hidden items-center justify-center">
        <p>{props.text}</p>
      </div>
    </Link>
  );
};
