import React from "react";
import { Link } from "react-router-dom";
import { IcLogo } from "assets";

export const Logo = (props) => {
  return (
    <Link to={"/"} className="flex items-center">
      <img className=" ic-logo" src={IcLogo} />
      <div className="">
        <h1 className="logo-title ">Senimen</h1>
        <p className="logo-desc">Интернет магазин авторских книг</p>
      </div>
    </Link>
  );
};
