import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppNavigation from "./AppNavigation";
import I18n from "translations/I18n";

export const App = () => {
  return (
    <React.StrictMode>
     
      <BrowserRouter>
        <AppNavigation />
      </BrowserRouter>
    </React.StrictMode>
  );
};
