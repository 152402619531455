export default {
  catalog: "Каталог",
  aboutProject: "О проекте",
  register: "Регистрация",
  signIn: "Войти",
  buyForRead: "Купить для прочтения",
  nowReading: "Сейчас читают",
  showMore: "Показать еще",
  autor: "Автор",
  weRecomend: "Мы рекомендуем",
  toCatalog: "Перейти в каталог",
  moreInfo: "Подробнее",
  newBook: "Новая книга",
  projectInfo:
    " — это современная библиотека в интернете, которая открывает вам доступ к каталогу бестселлеров и классической литературы, включая новинки разных жанров: от художественных до научно-популярных книг и деловой литературы. ",
  whyWeCreate:
    "Именно для тех, кто читает много и с удовольствием, мы решили сделать Senimen — клуб-библиотеку, вступив в который вы сможете читать не только книги, которые у вас уже есть, но и издания из нашего обширного каталога.",
  main: "Главная",
  genre: "Жанр",
  whyNeedToRead: "Почему стоит приобрести эту книгу",
  chapterContent: "Содержание глав",
  buyFor: "Купить за",
  toFavorite: "В избранное",
  share: "Поделиться",
  chapter: "Глава",
  reviews: "Отзывы",
  reviewsLength: "Отзывов",
  rating: "Рейтинг",
  question: "Вопрос",
  apply: "Применить",
  autors: "Авторы",
  autorBooks: "Книги автора",
  privateCabinet: "Личный кабинет",
  settings: "Настройки",
  passTest: "Пройти тест",
  toPassTest: "Для проверки знаний вы можете пройти тест по прочитанной главе.",
  ru: "Русский",
  en: "English",
  filter: "Фильтры",
  show: "Показать",
  enter: "Вход",
  enterFromAccount: "Вход/Регистрация через аккаунт",
  fillFields: "Заполните поля",
  registerFromAccount: "Регистрация через аккаунт",
  fio: "Имя",
  email: "Email",
  password: "Пароль",
  passwordConfirm: "Повторите пароль",
  error_fio: "Имя должно быть длиннее 3 символов",
  error_email: "Email не валидный",
  error_password:
    "Не менее 6 символов, включая заглавные, прописные, цифровые значения. Максимальная длина - 20 символов.",
  error_passwordConfirm: "Пароли не совпадают",
  boughts: "Покупки",
  favorites: "Избранное",
  settings: "Настройки",
  myTests: "Мои тесты",
  logout: "Выйти",
  myData: "Мои данные",
  changeData: "Изменить данные",
  notFound: "Не найдено :(",
  privatePolicy: "Политика конфиденциальности",
  paymentPolicy: "Политика оплаты",
  readBook: "Читать книгу",
  yourAnswer: "Ваш ответ",
  endTest: "Завершить тест",
  returnToBook: "Вернуться к книге",
  yourResult: "Ваш результат",
  testResult: "Результаты теста",
  pageNotFound: "Увы нет такой страницы",
  goToMainPage: "Перейти на главную",
  forgotPassword: "Забыл пароль",
  reset: "Восстановить",
  saveBookMark: "Сохранить закладку",
  bookmarkName: "Название закладки",
  cancel: "Отмена",
  save: " Cохранить",
  bookmarks: "Закладки",
  settings: "Настройки",
  changeFontSize: "Шрифт",
  selectPlatform: "Выберите платформу",
  pleaseEnterName: "Для удобности пользования введите ваше имя",
  next: "Продолжить",
  removeFavorite: "Убрать из избранного",
  author: "Автор",
  "User already exists": "Пользователь уже зарегистрирован",
  postReview: "Отзыв о книге",
  shareThoughts: "Поделитесь вашими мыслями о прочитанном",
  sendReview: "Оставить отзыв",
  reviewsList: "Отзывы",
  reviews: {
    0: "0 Отзывов",
    1: "1 Отзыв",
    2: "{{count}} Отзыва",
    3: "{{count}} Отзыва",
    4: "{{count}} Отзыва",

    "5+": "{{count}} Отзывов",
  },
  emailVerified: "Почта успешно подтверждена!",
  profile: "Личный кабинет",
  goBack: "Вернуться назад",
  deleteAccount: "Удалить аккаунт",
};
