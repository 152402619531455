import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ImgBookCover, ImgCreator } from "assets";

import { MenuNav } from "components/MenuNav";
import { BookBanner } from "components/BookBanner";
import { Carousel } from "react-responsive-carousel";
import { Review } from "components/Review";
import { AutorBanner } from "components/AutorBanner";
import { inject, observer } from "mobx-react";
import { Link, useLocation, useParams } from "react-router-dom";
import { NotFound } from "./NotFound";
import { encrypt } from "utils/encription";
import md5 from "js-md5";

export const Book = inject(
  "bookStore",
  "authStore"
)(
  observer((props) => {
    const { bookStore, authStore } = props;
    const { book } = bookStore;
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams();
    const carouselRef = useRef(null);
    const [carousel, setCarousel] = useState(0);
    const [routes, setRoutes] = useState([]);

    const isOwned = useMemo(() => {
      return (
        props.authStore.profile?.books_owned?.filter((fav) => {
          if (fav.book) {
            return fav?.book?._id === book?._id;
          }
        }).length > 0
      );
    }, [book, props.authStore.profile?.books_owned]);

    useEffect(() => {
      const newRoutes = [
        {
          name: t("main"),
          to: "/",
        },
        {
          name: t("catalog"),
          to: "/catalog",
        },
        {
          name: book?.name,
          to: `/catalog/${book?._id}`,
        },
      ];
      setRoutes(newRoutes);
    }, [book]);

    const handleCarouselChange = (index) => {
      setCarousel(index);
    };

    const handleBook = useCallback(() => {
      props.bookStore.fetchBook(params.id);
    }, [params.id, props.bookStore]);

    const handlePayment = useCallback(() => {
      const { book } = bookStore;
      if (authStore.profile) {
        const pId = encrypt(authStore.profile._id);
        const bId = encrypt(book._id);
        const SignatureValue = md5(
          `Senimen:${book.price}:0:LMWe2xkdLY3kNuh5mB07:Shp_1=${pId}:Shp_2=${bId}`
        );
        // const SignatureValue = md5(`Senimen:100:0:m4MCX48IJQyDu1mlIx7E:Shp_1=${authStore.profile._id}:Shp_2=${book._id}`);
        window.Robokassa.StartPayment({
          MerchantLogin: "Senimen",
          OutSum: book.price,
          InvId: 0,
          Description: "Оплата заказа в магазине Senimen",
          Culture: "ru",
          Encoding: "utf-8",
          SignatureValue,
          Shp_1: pId,
          Shp_2: bId,
          // IsTest: true,
        });
      } else {
        bookStore.handleModal(true);
      }
    }, [authStore.profile, bookStore]);

    useEffect(() => {
      handleBook();
    }, []);

    useEffect(() => {
      window.scrollTo({
        top: 0,
      });
    }, []);

    useEffect(() => {
      // Function to check visibility and handle visibility change
      const handleVisibilityChange = (node) => {
        const visibility = node.style.visibility;
        if (visibility === "hidden") {
          console.log("iframe is now hidden, reloading page...");
          window.location.reload(); // Reloads the current page
        }
      };

      // Select the target element
      const targetNode = document.getElementById("robokassa_iframe");
      if (!targetNode) {
        console.log("Target node not found");
        return;
      }

      // Initialize MutationObserver and define the callback
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            handleVisibilityChange(mutation.target);
          }
        }
      });

      // Configuration of the observer:
      const config = { attributes: true, attributeFilter: ["style"] };

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config);

      // Cleanup function to disconnect observer on component unmount
      return () => {
        observer.disconnect();
      };
    }, []);

    return (
      <>
        {props.bookStore.loading.book ? (
          <p>...loading</p>
        ) : !props.bookStore.book ? (
          <NotFound />
        ) : (
          <>
            <div>
              <div className="hidden xl:block mb-10">
                <MenuNav routes={routes} />
              </div>
              <section>
                <BookBanner
                  bookStore={bookStore}
                  onFavorite={handleBook}
                  authStore={authStore}
                  book={bookStore.book}
                />
              </section>

              {bookStore.book?.whyNeedBuy?.length > 0 && (
                <>
                  <div className="section-divider" />

                  <section className="section-padding">
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-20">
                      <div>
                        <h2 className="section-title mb-8 xl:mb-0">
                          {t("whyNeedToRead")}
                        </h2>
                      </div>
                      <div className="col-span-2 ">
                        <Carousel
                          ref={carouselRef}
                          onChange={(cal) => {
                            handleCarouselChange(cal);
                          }}
                          showIndicators={false}
                          showThumbs={false}
                          showStatus={false}
                          autoPlay
                          emulateTouch
                          swipeable
                        >
                          {bookStore.book.whyNeedBuy?.map((answer) => {
                            return (
                              <div className="h-auto xl:h-100">
                                <p className="p2 text-left">{answer}</p>
                              </div>
                            );
                          })}
                        </Carousel>
                        <div className="thumb-wrap">
                          {bookStore.book.whyNeedBuy?.map((c, i) => {
                            return (
                              <button
                                onClick={() => carouselRef.current.moveTo(i)}
                                className={`dot-indicator ${
                                  carousel === i && "dot-indicator-selected"
                                }`}
                              />
                            );
                          })}
                        </div>
                        {isOwned ? null : (
                          <div className="hidden xl:flex">
                            <button
                              onClick={handlePayment}
                              className=" default-button yellow  "
                            >
                              <p className="p3">
                                {t("buyFor")} {bookStore.book.price} ₸
                              </p>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {isOwned ? null : (
                      <button className="flex xl:hidden default-button yellow w-full">
                        <p className="p3">
                          {t("buyFor")} {bookStore.book.price} ₸
                        </p>
                      </button>
                    )}
                  </section>
                </>
              )}

              {bookStore.book.chapters.length > 0 && (
                <>
                  <div className="section-divider" />

                  <section className="section-padding">
                    <div className="section-blue-padding">
                      <h2 className="section-title mb-12 xl:mb-28">
                        {t("chapterContent")}
                      </h2>
                      <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-32 gap-y-10">
                        {bookStore.book.chapters?.map((c, i) => {
                          return (
                            <div>
                              <h4 className="chapter-numbers">
                                {t("chapter")} {i + 1}
                              </h4>
                              <p className="chapter-info">{c.description}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                </>
              )}

              <div className="section-divider" />

              <section className="section-padding">
                <div className="header-bb">
                  <h2 className="section-title small">
                    {t("reviewsList")}{" "}
                    <span>{bookStore?.book?.reviews?.length}</span>
                  </h2>
                </div>
                <div className="hidden xl:flex flex-1  mt-28">
                  <Carousel
                    centerMode
                    centerSlidePercentage={36}
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                    className="w-full"
                  >
                    {bookStore?.book?.reviews?.map((rev, i) => {
                      return <Review review={rev} />;
                    })}
               
                  </Carousel>
                </div>
                <div className="block xl:hidden">
                  <Carousel
                    centerMode
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                  >
                    {bookStore?.book?.reviews?.map((rev, i) => {
                      return (
                        <div className="mr-4">
                          <Review review={rev} />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </section>
              <div className="section-divider" />

              <section>
                <AutorBanner author={bookStore.book.author} />
              </section>
            </div>
          </>
        )}
      </>
    );
  })
);
