import { Banner } from "components/Banner";
import { BookCard } from "components/BookCard";
import { IcArrowRight, ImgCreator } from "assets";
import { Link, useParams } from "react-router-dom";
import { t } from "i18next";
import Carousel from "components/Carousel";
import { YellowButton } from "components/YellowButton";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { NotFound } from "components/NotFound";

export const Main = inject(
  "bookStore",
  "authStore"
)(
  observer((props) => {
    const { pr } = useParams();
    const { bookStore, authStore } = props;

    useEffect(() => {
      bookStore.fetchMain();
    }, []);

    useEffect(() => {
      // Function to perform smooth scroll
      const scrollToAnchor = () => {
        const hash = window.location.hash; // Get the hash from the URL
        if (hash) {
          const targetElement = document.getElementById(hash.slice(1)); // Remove the '#' and get the element
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" }); // Scroll to the element smoothly
          }
        }
      };

      scrollToAnchor(); // Call the function on component mount
      window.addEventListener("hashchange", scrollToAnchor); // Add event listener for hash changes

      return () => {
        window.removeEventListener("hashchange", scrollToAnchor); // Clean up the event listener
      };
    }, []);

    useEffect(() => {
      const anchor = window.location.hash.slice(1);
      if (anchor) {
        const anchorEl = document.getElementById(anchor);
        if (anchorEl) {
          anchorEl.scrollIntoView();
        }
      } else {
        window.scrollTo({
          top: 0,
        });
      }
      bookStore.fetchAll();
    }, []);

    return (
      <div className="">
        <Banner authStore={authStore} banner={bookStore.banner[0]} />
        <div className="section-divider" />
        {bookStore.nowReading.length > 0 && (
          <section className="block xl:hidden section-padding">
            <h2 className="section-title">{t("nowReading")}</h2>
            <div className="flex flex-row overflow-scroll mb-10">
              {bookStore.nowReading.length > 0 ? (
                bookStore.nowReading.slice(0, 3).map((book) => {
                  return <BookCard book={book} />;
                })
              ) : (
                <NotFound />
              )}
            </div>
            <YellowButton to="/catalog" text={t("showMore")} />
          </section>
        )}
        {bookStore.nowReading.length > 0 && (
          <section className="hidden xl:block">
            <div className="grid grid-cols-1 xl:grid-cols-4 grid-rows-4 xl:grid-rows-1 grid-flow-col auto-cols-max gap-20">
              <div className=" justify-between flex flex-col">
                <h2 className="section-title">{t("nowReading")}</h2>
                <Link to="/catalog" className="default-button yellow">
                  <p>{t("showMore")}</p>
                  <img src={IcArrowRight} />
                </Link>
              </div>
              {bookStore.nowReading.length > 0 ? (
                bookStore.nowReading.slice(0, 3).map((book) => {
                  return <BookCard book={book} />;
                })
              ) : (
                <NotFound />
              )}
            </div>
          </section>
        )}

        <div className="section-divider" />

        {bookStore?.weRecomend?.length > 0 && (
          <section>
            <div className="section-blue">
              <div className="flex justify-between mb-5 xl:mb-20">
                <h2 className="ml-0 xl:ml-20 section-title">
                  {t("weRecomend")}
                </h2>
              </div>
              <div className="mb-10 ml-0 xl:ml-20 ">
                <Carousel items={bookStore.weRecomend} />
              </div>
              <div className="hidden xl:flex items-center justify-center">
                <Link to="/catalog" className="default-button yellow">
                  <p>{t("toCatalog")}</p>
                  <img src={IcArrowRight} />
                </Link>
              </div>
              <YellowButton to="/catalog" text={t("showMore")} />
            </div>
          </section>
        )}

        <div
          id="about-us"
          style={{
            marginBottom: 20,
          }}
          className="section-divider"
        />

        <section className="section-padding">
          <h2 className="section-title mb-3 xl:mb-15">{t("aboutProject")}</h2>
          <p className="about-desc mb-10 xl:mb-28">
            <span>Senimen</span> {t("projectInfo")}
          </p>
          <div className="hidden  xl:flex">
            <div className="flex-1 flex flex-col items-center justify-between ">
              <p className="creator-desc"> {t("whyWeCreate")}</p>
              <Link to="/about" className="default-button yellow">
                <p>{t("moreInfo")}</p>
                <img src={IcArrowRight} />
              </Link>
            </div>
            <div className="flex-1">
              <img className="creator-image" src={ImgCreator} />
            </div>
          </div>
          <div className="block xl:hidden">
            <div className="flex-1">
              <img className="creator-image" src={ImgCreator} />
            </div>
            <p className="creator-desc"> {t("whyWeCreate")}</p>
          </div>
          <YellowButton to="/about" text={t("moreInfo")} />
        </section>

        <div className="section-divider" />

        <section>
          <div className="section-blue padding">
            <div className="flex justify-between mb-3 xl:mb-28">
              <h2 className="section-title">{t("catalog")}</h2>
            </div>
            <div className="flex xl:hidden flex-row overflow-scroll mb-10">
              {bookStore.books.length > 0 ? (
                bookStore.books.slice(0, 3).map((book) => {
                  return <BookCard full book={book} />;
                })
              ) : (
                <NotFound />
              )}
            </div>
            <div className="hidden  xl:grid grid-cols-1 xl:grid-cols-4 grid-rows-4 xl:grid-rows-1 grid-flow-col auto-cols-max gap-10 overflow-scroll">
              {bookStore.books.length > 0 ? (
                bookStore.books.slice(0, 3).map((book) => {
                  return <BookCard book={book} />;
                })
              ) : (
                <NotFound />
              )}
              <div>
                <Link to="/catalog" className="default-button yellow">
                  <p>{t("showMore")}</p>
                  <img src={IcArrowRight} />
                </Link>
              </div>
            </div>
            <YellowButton to="/catalog" text={t("showMore")} />
          </div>
        </section>
      </div>
    );
  })
);
