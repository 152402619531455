import React, { useState, useEffect } from "react";
import { MenuNav } from "components/MenuNav";
import { useTranslation } from "react-i18next";
import { AutorBanner } from "components/AutorBanner";
import { BookCard } from "components/BookCard";

import { ImgBookCover, IcArrowRight, ImgCreator } from "assets";
import { Link } from "react-router-dom";

const books = [
  {
    id: 400,
    autor: "Ольга Лермонтова ",
    title: "Работа по любви. Как построить успешную.",
    price: "549",
    image: ImgBookCover,
  },
  {
    id: 401,
    autor: "Ольга Лермонтова ",
    title: "Работа по любви. Как построить успешную.",
    price: "549",
    image: ImgBookCover,
  },
  {
    id: 402,
    autor: "Ольга Лермонтова ",
    title: "Работа по любви. Как построить успешную.",
    price: "549",
    image: ImgBookCover,
  },
  {
    id: 404,
    autor: "Ольга Лермонтова ",
    title: "Работа по любви. Как построить успешную.",
    price: "549",
    image: ImgBookCover,
  },
  {
    id: 405,
    autor: "Ольга Лермонтова ",
    title: "Работа по любви. Как построить успешную.",
    price: "549",
    image: ImgBookCover,
  },
  {
    id: 406,
    autor: "Ольга Лермонтова ",
    title: "Работа по любви. Как построить успешную.",
    price: "549",
    image: ImgBookCover,
  },
  {
    id: 407,
    autor: "Ольга Лермонтова ",
    title: "Работа по любви. Как построить успешную.",
    price: "549",
    image: ImgBookCover,
  },
  {
    id: 408,
    autor: "Ольга Лермонтова ",
    title: "Работа по любви. Как построить успешную.",
    price: "549",
    image: ImgBookCover,
  },
];

const book = {
  id: 400,
  autor: {
    name: "Анвар Бакиев",
    desc: "Уильям Шекспир — английский поэт и драматург, зачастую считается величайшим англоязычным писателем и одним из лучших драматургов мира. Часто именуется национальным поэтом Англии. Дошедшие до нас работы, включая некоторые, написанные совместно с другими авторами, состоят из 38 пьес, 154 сонетов, 4 поэм и 3 эпитафий.",
    image: ImgCreator,
    socials: [
      {
        name: "instagram",
        url: "",
      },
      {
        name: "facebook",
        url: "",
      },
      {
        name: "vk",
        url: "",
      },
    ],
  },
};

export const Autor = () => {
  const { t } = useTranslation();

  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const newRoutes = [
      {
        name: t("main"),
        to: "/",
      },
      {
        name: t("autors"),
        to: "/autors",
      },
      {
        name: "Autor Name",
        to: "/401",
      },
    ];
    setRoutes(newRoutes);
  }, []);

  return (
    <div>
      <div className="mb-10">
        <MenuNav routes={routes} />
      </div>
      <section>
        <AutorBanner theme="gray" autor={book.autor} />
      </section>
      <div className="section-divider" />
      <section>
        <div className="grid grid-cols-4 grid-flow-col auto-cols-max gap-20">
          <div className=" justify-between flex flex-col">
            <h2 className="section-title">{t("autorBooks")}</h2>
            <Link className="default-button yellow">
              <p>{t("showMore")}</p>
              <img src={IcArrowRight} />
            </Link>
          </div>
          {books.slice(0, 3).map((book) => {
            return (
              <div>
                <BookCard book={book} />
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};
