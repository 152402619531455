import { Slider, debounce } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

export const AnswerTest = ({ index, question, onAnswer }) => {
  const { t } = useTranslation();

  const handleAnswer = debounce((text) => {
    onAnswer(text, question);
  }, 500);
  
  return (
    <div className="question-sec">
      <span className="q-sc-i">
        {t("question")} {index}
      </span>
      <article className="q-sc-q">{question.question}</article>
      <div
        style={{
          paddingBottom: 20,
          paddingTop: 20,
        }}
        className="slider-wrap"
      >
        <span className="ss-a-po">{t("yourAnswer")}</span>
        <input
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: "5px",
            marginTop: 10,
          }}
          onChange={(e) => handleAnswer(e.target.value)}
          placeholder="Ваш ответ"
        />
      </div>
    </div>
  );
};
