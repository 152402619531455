import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import { useMemo } from "react";
import { inject, observer } from "mobx-react";
import { debounce } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export const options = {
  title: "",
  chartArea: { width: "50%" },
  bar: { groupWidth: "50%" },
  legend: { position: "none" },
};

export const TestResult = inject("bookStore")(
  observer(({ test, questions, onClose, bookStore, testResult = null }) => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const answeras = useMemo(() => {
      return test.testType === "FREE_ANSWER"
        ? JSON.stringify(questions.map((q) => q.answer))
        : testResult || questions.reduce((a, b) => a + b.answer, 0);
    }, [questions, test, testResult]);

    const maxResult = useMemo(() => {
      return Math.max(...test.results.map((res) => res.rangeEnd));
    }, [test.results]);

    const data = useMemo(() => {
      if (typeof questions[0].answer === "number") {
        const list = questions.map((q) => [
          q.question_promo,
          q.answer,
          "#213669",
          null,
        ]);
        return [
          [
            "Element",
            "",
            { role: "style" },
            {
              sourceColumn: 5,
              role: "annotation",
              type: "string",
              calc: "stringify",
            },
          ],
          ...list,
        ];
      }
      return [];
    }, [questions]);

    const result = useMemo(() => {
      return test.results.filter(
        (res) => res.rangeStart <= answeras && res.rangeEnd >= answeras
      )[0];
    }, [answeras, test.results]);

    const valueFormatter = (value) => `${value}`;

    const renderChart = useCallback(() => {
      return (
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data}
          options={{
            ...options,

            hAxis: {
              ticks: [0, 1, 2, 3, 4, 5],

              viewWindow: {
                min: 0,
                max: 5,
              },
            },
          }}
        />
      );
    }, [data]);

    const handleSaveTest = () => {
      if (!testResult)
        bookStore.saveTestResult(
          answeras,
          JSON.stringify({ ...test, questions })
        );
      else if (testResult) {
        navigate(`/reader?id=${test.book}&page=${test.cfiCode}`);
      }
    };

    function isValidJSON(string) {
      try {
        JSON.parse(string); // Try to parse the string as JSON
        return true; // If parsing succeeds, return true
      } catch (error) {
        return false; // If an error is thrown, return false
      }
    }

    return (
      <>
        <div className="modal-box">
          <h2 className="child-modal-title">{t("testResult")}</h2>
          <div className="asdlo-ads">
            {test.testType === "FREE_ANSWER" ? (
              questions.map((q, index) => {
                if (isValidJSON(testResult)) {
                  const results = JSON.parse(testResult);
                  console.log(q);
                  return (
                    <div>
                      <span
                        style={{
                          fontWeight: "600",
                        }}
                        className="q-sc-q"
                      >
                        {console.log("test:")}
                        {q.question_promo}:{" "}
                        <span>
                          {q.answer ||
                            (typeof results === "object" && results[index])}
                        </span>
                      </span>
                    </div>
                  );
                } else {
                  return <div>Нет ответов по этому тесту</div>;
                }
              })
            ) : (
              <>
                {typeof questions[0].answer === "number" && renderChart()}
                <span className="result-title">
                  {t("yourResult")} {answeras} из {String(maxResult)}
                </span>
                <p className="result-desc">{result?.answer}</p>
              </>
            )}

            <div className="flex flex-col md:flex-row justify-around">
              <button
                onClick={() => {
                  onClose();
                  handleSaveTest();
                }}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 60,
                }}
                className="default-button yellow big-padding"
              >
                <p>{t("returnToBook")}</p>
              </button>
              {testResult && (
                <button
                  onClick={() => {
                    onClose();
                  }}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 60,
                  }}
                  className="default-button yellow big-padding"
                >
                  <p>{t("goBack")}</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  })
);
