import { makeObservable, observable, action } from "mobx";
import { Axios, configureAxios } from "utils/axios";
import { toast } from "react-toastify";
import {
  signInApi,
  signUpApi,
  getProfileApi,
  updateProfileApi,
  postFavoriteApi,
  resetPasswordApi,
  socialAuth,
  checkSocial,
  activateEmail,
  postDeleteAccount,
} from "api";
import { Navigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";

export default class AuthStore {
  rootStore;
  isAuthenticated = false;
  token = null;
  profile = null;

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      token: observable,
      profile: observable,
      login: action,
      register: action,
      logout: action,
      getProfile: action,
      postFavorite: action,
    });

    const token = localStorage.getItem("token");
    if (token) {
      this.token = token;
      this.isAuthenticated = true;
      configureAxios(token);
      this.getProfile();
    }
  }

  login = async (email, password, onSuccess, onError) => {
    try {
      const response = await signInApi(email, password);
      const { token } = response.data;
      configureAxios(token);
      localStorage.setItem("token", token);
      this.token = token;
      this.isAuthenticated = true;
      onSuccess();
      this.getProfile();
      toast.success("Успешно", {
        position: "top-center",
        autoClose: 1200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
      onError(error);
    }
  };

  getProfile = async () => {
    try {
      const response = await getProfileApi();
      if (response) {
        this.profile = response.data.user;
      }
    } catch (error) {
      console.error(error);
    }
  };

  updateProfile = async (data, onSuccess, onError) => {
    try {
      const response = await updateProfileApi(data);
      if (response) {
        this.getProfile();
        toast.success("Успешно", {
          position: "top-center",
          autoClose: 1200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e) {
      if (onError) {
        onError();
      }
    }
  };

  register = async (data, onSuccess, onError) => {
    try {
      const response = await signUpApi(data);
      const { token } = response.data;
      configureAxios(token);
      localStorage.setItem("token", token);
      this.token = token;
      this.isAuthenticated = true;
      if (onSuccess) {
        onSuccess();
      }
      this.getProfile();
      toast.success("Успешно", {
        position: "top-center",
        autoClose: 1200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
      if (onError) {
        onError(error);
      }
    }
  };

  authSocial = async (data, onSuccess, onError) => {
    try {
      const response = await socialAuth(data);
      const { token } = response.data;
      configureAxios(token);
      localStorage.setItem("token", token);
      this.token = token;
      this.isAuthenticated = true;
      if (onSuccess) {
        onSuccess();
      }
      this.getProfile();
      toast.success("Успешно", {
        position: "top-center",
        autoClose: 1200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
      if (onError) {
        onError(error);
      }
    }
  };

  postAuth = async (data) => {
    try {
      const response = await checkSocial(data);
      return response.status;
    } catch (e) {}
  };

  postFavorite = async (book_id) => {
    try {
      const response = await postFavoriteApi(book_id);
      if (response.status === 200) {
        this.getProfile();
      }
    } catch (e) {}
  };

  resetPassword = async (email, onSuccess) => {
    try {
      const response = await resetPasswordApi(email);
      if (response.status === 200) {
        toast.success("Проверьте почту", {
          position: "top-center",
          autoClose: 1200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        if (onSuccess) {
          onSuccess();
        }
      } else {
        toast.error("Почта не найдена", {
          position: "top-center",
          autoClose: 1200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error("Почта не найдена", {
        position: "top-center",
        autoClose: 1200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  activateEmail = async (email, code) => {
    try {
      const response = await activateEmail({
        email,
        code,
      });
      console.log(response);
      if (response.status === 200) {
        toast.success("Почта подтверждена", {
          position: "top-center",
          autoClose: 1200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Почта не найдена", {
          position: "top-center",
          autoClose: 1200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error("Почта не найдена", {
        position: "top-center",
        autoClose: 1200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    // eslint-disable-next-line no-restricted-globals
    // location.href = "https://senimen.com";
  };

  deleteAccount = async () => {
    try {
      const response = await postDeleteAccount({
        id: this.profile._id,
      });
      if (response.status === 200) {
        this.logout();
        toast.success("Удалена", {});
      } else {
        toast.error("Попробуйте позже", {});
      }
    } catch (error) {
      toast.error("Попробуйте позже", {});
    }
  };

  logout = () => {
    localStorage.removeItem("token");
    googleLogout();
    this.token = null;
    this.isAuthenticated = false;
    this.profile = null;
  };
}
