import React, { useCallback, useMemo, useState } from "react";
import { IcHeartBlack, IcShareBlack, IcHeartFilled } from "assets";
import "styles/Components.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  EmailShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
  FacebookIcon,
} from "react-share";

import { YellowButton } from "components/YellowButton";

import md5 from "js-md5";
import { CONFIGS } from "utils/configs";
import { encrypt } from "utils/encription";
import { Dialog, DialogTitle } from "@mui/material";

export const BookBanner = (props) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const { book, authStore, onFavorite, bookStore } = props;

  const reviewText = useMemo(() => {
    return book.reviews?.length >= 5
      ? t("reviews.5+", { count: book.reviews?.length })
      : t(`reviews.${book.reviews?.length}`, { count: book.reviews?.length });
  }, []);

  const shareUrl = useMemo(() => {
    return `https://senimen.com/catalog/${book?._id}`;
  }, [book._id]);

  const favorite = useMemo(() => {
    return (
      props.authStore.profile?.favorites?.filter((fav) => {
        return fav?._id === book?._id;
      }).length > 0
    );
  }, [book._id, props.authStore.profile?.favorites]);

  const isOwned = useMemo(() => {
    return (
      props.authStore.profile?.books_owned?.filter((fav) => {
        if (fav.book) {
          return fav?.book?._id === book._id;
        }
      }).length > 0
    );
  }, [book._id, props.authStore.profile?.books_owned]);

  const handleFavorite = useCallback(
    (e) => {
      e.stopPropagation();
      props.authStore.postFavorite(book._id);
      onFavorite();
    },
    [book._id, onFavorite, props.authStore]
  );

  const handlePayment = useCallback(() => {
    if (authStore.profile) {
      const pId = encrypt(authStore.profile._id);
      const bId = encrypt(book._id);
      const SignatureValue = md5(
        `Senimen:${book.price}:0:LMWe2xkdLY3kNuh5mB07:Shp_1=${pId}:Shp_2=${bId}`
      );
      // const SignatureValue = md5(`Senimen:100:0:m4MCX48IJQyDu1mlIx7E:Shp_1=${authStore.profile._id}:Shp_2=${book._id}`);
      window.Robokassa.StartPayment({
        MerchantLogin: "Senimen",
        OutSum: book.price,
        InvId: 0,
        Description: "Оплата заказа в магазине Senimen",
        Culture: "ru",
        Encoding: "utf-8",
        SignatureValue,
        Shp_1: pId,
        Shp_2: bId,
        // IsTest: true,
      });
    } else {
      bookStore.handleModal(true);
    }
  }, [authStore.profile, book._id, bookStore]);

  return (
    <div>
      <div className="grid grid-cols-1 xl:grid-cols-3 section-blue-padding bb-sm-1 gap-10 xl:gap-20">
        <div>
          <img
            className="book-banner-image"
            src={`${CONFIGS.BASE_API}${book?.image}`}
            alt="book-cover"
          />
        </div>
        <div className="col-span-2 flex flex-col justify-between">
          <h4 className="book-banner-title mb-4">{props.book?.name}</h4>
          <div>
            <p className="book-banner-info">
              <span>{t("autor")}:</span>
              {book.author?.name} {book.author?.surname}
            </p>
            <p className="book-banner-info mb-2">
              <span>{t("genre")}:</span> {book.category?.name}
            </p>
            <p className="book-review-info">
              {book.rating || 0} {t("rating")} | {reviewText}
            </p>
          </div>
          <p className="book-info">{book.description}</p>
          <div className="hidden xl:block mt-8">
            <div className="w-half flex flex-col mb-10">
              {CONFIGS.BASE_API !== "http://localhost:4000/api" && (
                <script
                  type="text/javascript"
                  src="https://auth.robokassa.kz/Merchant/bundle/robokassa_iframe.js"
                ></script>
              )}

              <script
								type="text/javascript"
								src="https://auth.robokassa.kz/Merchant/PaymentForm/FormSS.js?MerchantLogin=Senimen&InvoiceID=0&Culture=ru&Encoding=utf-8&OutSum=700&shp_interface=field&SignatureValue=7a38fa57f84a7fe2d94ad41e038d38e1"
							></script>
              {isOwned ? (
                <Link
                  to={`/reader?id=${book._id}`}
                  className="default-button yellow"
                >
                  <p className="text-center w-full">{t("readBook")}</p>
                </Link>
              ) : (
                <input
                  type="submit"
                  className="default-button red big-padding white-text"
                  value={`${t("buyFor")} ${book.price} ₸`}
                  onClick={handlePayment}
                />
              )}
            </div>
            <div className="w-half grid grid-cols-2 gap-10">
              <button
                onClick={handleFavorite}
                className="default-button big-padding"
              >
                <p>{favorite ? t("removeFavorite") : t("toFavorite")}</p>
              </button>

              <button
                onClick={() => setVisible(true)}
                className="default-button big-padding"
              >
                <p>{t("share")}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="section-padding flex  xl:hidden">
        {isOwned ? (
          <div className="flex mr-5">
            <YellowButton to={`/reader?id=${book._id}`} text={t("readBook")} />
          </div>
        ) : (
          <input
            type="submit"
            className="default-button flex flex-1 red big-padding mr-4 white-text"
            value={`${t("buyFor")} ${book.price} ₸`}
            onClick={handlePayment}
          />
        )}

        {/* <Link
					to="/reader"
					className="default-button flex flex-1 red big-padding mr-4"
				>
					<p className="p3">
						{t('buyFor')} {book.price} ₸
					</p>
				</Link> */}
        <button onClick={handleFavorite} className="mr-4">
          <img src={favorite ? IcHeartFilled : IcHeartBlack} alt="favorite" />
        </button>
        <button onClick={() => setVisible(true)} className="mr-2">
          <img src={IcShareBlack} alt="share" />
        </button>
      </div>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <div className="p-6 flex flex-col gap-10">
          <h1
            style={{
              fontSize: 18,
              fontWeight: "700",
            }}
          >
            {t("selectPlatform")}
          </h1>
          <div className="flex gap-10">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>

            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>
            <TelegramShareButton url={shareUrl}>
              <TelegramIcon size={40} round={true} />
            </TelegramShareButton>
            <EmailShareButton url={shareUrl}>
              <EmailIcon size={40} round={true} />
            </EmailShareButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
