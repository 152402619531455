import { useMemo } from "react";
import "styles/Components.css";
import { ImgBookCover } from "assets";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CONFIGS } from "utils/configs";
import { YellowButton } from "components/YellowButton";

export const Banner = ({ banner, authStore }) => {
  const { t } = useTranslation();
  const isOwned = useMemo(() => {
    return (
      authStore.profile?.books_owned?.filter((fav) => {
        if (fav.book) {
          return fav?.book?._id === banner?._id;
        }
      }).length > 0
    );
  }, [banner?._id, authStore?.profile?.books_owned]);


  return (
    <div className="banner-wrap flex flex-row justify-between">
      <div className="banner-info">
        <div className="flex mb-8">
          <div className="tag-wrap">
            <p className="info-title">{t("newBook")}</p>
          </div>
        </div>

        <div className="hidden xl:block">
          <h4 className="banner-title">{banner?.name}</h4>
          <p className="banner-author mb-20">
            {t("author")}: {banner?.author?.name} {banner?.author?.surname}
          </p>
          <p className="banner-desc">
            {banner?.description?.substring(0, 300)}
            {banner?.description.length > 300 ? "..." : ""}
          </p>
        </div>
        <div className="block xl:hidden banner-desc-small">
          <h4 className="banner-title">{banner?.name}</h4>
          <div className="flex flex-row">
            <div className=" flex">
              <img
                className="book-cover"
                src={`${CONFIGS.BASE_API}${banner?.image}`}
                alt="book_cover"
              />
            </div>
            <div className="flex flex-1">
              <p className="banner-desc">
                {banner?.description?.substring(0, 300)}
                {banner?.description.length > 300 ? "..." : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="hidden xl:flex">
          {isOwned ? (
            <div className="flex mr-5">
              <Link
                to={`/reader?id=${banner._id}`}
                className="default-button yellow"
              >
                <p className="text-center w-full">{t("readBook")}</p>
              </Link>
            </div>
          ) : (
            <Link to={`/catalog/${banner?._id}`} className="default-button red">
              <p>
                <span>{banner?.price} KZT</span> {t("buyForRead")}
              </p>
            </Link>
          )}
        </div>
        <div className="block xl:hidden">
          {isOwned ? (
            <div className="flex mr-5">
              <YellowButton
                to={`/reader?id=${banner?._id}`}
                text={t("readBook")}
              />
            </div>
          ) : (
            <Link to={`/catalog/${banner?._id}`} className="default-button red">
              <p>
                <span>{banner?.price}KZT</span> {t("buyForRead")}
              </p>
            </Link>
          )}
        </div>
      </div>

      <div className="flex-1 hidden xl:flex">
        <img
          className="book_cover_big"
          src={`${CONFIGS.BASE_API}${banner?.image}`}
          alt="book_cover"
        />
      </div>
    </div>
  );
};
