// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SIGN_IN: "auth/signIn",
  SING_UP: "auth/signUp",
  SOCIAL_AUTH: "auth/socialAuth",

  PROFILE: "auth/profile",
  RESET_PASSWORD: "auth/reset",
  BOOKS: "books/",
  AUTORS: "books/autors",
  CATEGORIES: "books/categories",
  BOOK_ID: "books/",
  PROFILE_UPDATE: "auth/profile",
  BOOK_FAVORITE: "books/favorite",
  SOCIAL: "auth/social",
  CHECK: "auth/check",
  BOOK_PAGE: "books/bookRead",
  TEST_RESULT: "books/test",
  ADD_BOOKMARK: "books/add-bookmark",
  REMOVE_BOOKMARK: "books/remove-bookmark",

  ADD_REVIEW: "books/:id/reviews",
  ACTIVE_EMAIL: "auth/activate",
  DELETE_USER: "auth/delete",
};
