import { t } from "i18next";
import React from "react";

export const InputField = (props) => {
  return (
    <div className="polca">
      <label className={`input-field-wrap ${props.error && "error"}`}>
        <p>{props.placeholder}</p>
        <input
          onChange={(e) => props.onChange(e.target.value)}
          className="flex"
          type={props.type || "text"}
          placeholder={"Text"}
        />
      </label>
      <p className="error-text">{props.error && t(`error_${props.name}`)}</p>
    </div>
  );
};
