import React from "react";
import { useTranslation } from "react-i18next";
import { SocialIcon } from "./SocialIcon";
import { CONFIGS } from "utils/configs";

export const AutorBanner = (props) => {
  const { t } = useTranslation();
  const { author, theme } = props;
  if (theme == "gray") {
    return (
      <div className="grid grid-cols-5 autor-banner-wrap gray gap-60">
        <div className="col-span-2">
          <img src={`${CONFIGS.BASE_API}${author?.image}`} />
        </div>
        <div className="col-span-3 flex flex-col">
          <div className="flex flex-1 flex-col  justify-center">
            <h4 className="autor-banner-title gray">{author?.name} {author?.surname}</h4>
            <p className="autor-banner-desc gray">{author?.description}</p>
          </div>
          <div className="flex">
            <SocialIcon social={author?.instagram} />
            <SocialIcon social={author?.facebook} />
            <SocialIcon social={author?.vk} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-1 xl:grid-cols-7 autor-banner-wrap gap-60">
      <div className="col xl:col-span-4">
        <img
          className="block xl:hidden autor-banner-image"
          src={`${CONFIGS.BASE_API}${author?.image}`}
        />
        <p className="hidden xl:block autor-banner-tag">{t("autor")}</p>
        <div>
          <h4 className="autor-banner-title">
            {author?.name} {author?.surname}
          </h4>
          <p className="autor-banner-desc">{author?.description}</p>
        </div>
        <div className="flex">
          <SocialIcon social={author?.instagram} />
          <SocialIcon social={author?.facebook} />
          <SocialIcon social={author?.vk} />
        </div>
      </div>
      <div className="hidden xl:block col-span-3">
        <img
          className="author-banner-image"
          src={`${CONFIGS.BASE_API}${author?.image}`}
        />
      </div>
    </div>
  );
};
