import { useCallback, useEffect, useState } from "react";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { makeid } from "utils/encription";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const VK = inject("authStore")(
  observer(({ authStore }) => {
    let navigate = useNavigate();

    const { t } = useTranslation();
    const params = useParams();

    const [form, setForm] = useState({
      email: "",
      password: "",
      fio: "",
      passwordConfirm: "",
    });
    const [valid, setValid] = useState({
      email: true,
      password: true,
      fio: true,
      passwordConfirm: true,
    });

    const handleChange = (event) => {
      setForm({
        ...form,
        [event.target.id]: event.target.value,
      });
      switch (event.target.id) {
        case "fio": {
          const v = event.target.value.length > 3;
          setValid({
            ...valid,
            [event.target.id]: v,
          });
          return;
        }
      }
    };

    const handleSocial = useCallback(() => {
      const urlParams = new URLSearchParams(window.location.hash.substring(1));
      const userId = urlParams.get("user_id");
      const data = {
        email: userId,
        fio: form.fio,
        password: makeid(9),
        provider: "VK",
      };
      console.log(authStore);
      authStore.authSocial(data, () => {
        navigate("/");
      });
    }, [authStore, form.fio, navigate]);

    const renderField = (name, type) => {
      return (
        <div className="polca">
          <label
            className={`input-field-wrap ${valid[name] === false && "error"}`}
          >
            <p>{t(name)}</p>
            <input
              id={name}
              value={form[name]}
              className="flex"
              type={type || "text"}
              onChange={(e) => handleChange(e)}
            />
          </label>
          <p className="error-text">{!valid[name] && t(`error_${name}`)}</p>
        </div>
      );
    };

    const handleAuth = useCallback(async () => {
      const urlParams = new URLSearchParams(window.location.hash.substring(1));
      const userId = urlParams.get("user_id");
      const status = await authStore.postAuth({
        id: userId,
      });
      if (status === 200) {
        handleSocial();
      }
    }, [authStore, handleSocial]);

    useEffect(() => {
      handleAuth();
    }, []);
    return (
      <div className="social-login">
        <div className="card">
          <h2>{t("pleaseEnterName")}</h2>
          {renderField("fio", "text")}
          <button
            onClick={handleSocial}
            disabled={form.fio.length < 3}
            className="default-button"
          >
            <span className="about-desc text-white">{t("next")}</span>
          </button>
        </div>
      </div>
    );
  })
);
