import { t } from "i18next";
import React from "react";
import { useState } from "react";
import { Slider } from "@mui/material";

export const TestQuestion = (props) => {
  const [count, setCount] = useState(1);
  const { question, index } = props;
  function valuetext(value: number) {
    return `${value}°C`;
  }

  const renderCount = () => {
    const marks = [
      {
        value: 10,
        label: "1",
      },
      {
        value: 20,
        label: "2",
      },

      {
        value: 30,
        label: "3",
      },
      {
        value: 40,
        label: "4",
      },
      {
        value: 50,
        label: "5",
      },
    ];

    return (
      <div className="discrete-slider">
        <Slider
          aria-label="Custom marks"
          defaultValue={10}
          step={10}
          min={10}
          max={50}
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          marks={marks}
        />
      </div>
    );
  };

  const renderSelect = () => {
    return <div></div>;
  };
  const renderTextInput = () => {
    return (
      <div className="discrete-slider">
        {[1, 2, 3, 4, 5].map((i) => {
          return (
            <div>
              <p>{i}</p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <span className="question-number">
        {t("question")} {index}
      </span>
      <p className="question-title">{question.name}</p>
      {question.type === "counter"
        ? renderCount()
        : question.type === "select"
        ? renderSelect()
        : renderTextInput}
    </div>
  );
};
