import CircularProgress from "@mui/material/CircularProgress";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const EmailConfirmation = inject("authStore")(
  observer((props) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      props.authStore.activateEmail(
        searchParams.get("email"),
        searchParams.get("code")
      );
    }, []);

    return (
      <div className="flex flex-1 flex-col items-center justify-center gap-6">
        {<span className="text-4xl font-semibold">{t("emailVerified")}</span>}

        <button
          // eslint-disable-next-line no-restricted-globals
          onClick={() => (location.href = "https://senimen.com/catalog")}
          className="default-button big-padding"
        >
          <p>{t("Перейти в каталог")}</p>
        </button>
      </div>
    );
  })
);
