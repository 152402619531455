import { Box, Button, Modal } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SliderTest } from "./SliderTest";
import { TestTest } from "./PollTest";
import { AnswerTest } from "./AnswerTest";
import { TestResult } from "./TestResult";
import { inject, observer } from "mobx-react";
import { IcCloseMenu } from "assets";
import { isMobile } from "react-device-detect";

const style = {
  position: "absolute",
  bgcolor: "#EFF3F7",
  boxShadow: 24,
  height: "100vh",
  pt: 2,
  px: 4,
};

export const TestModal = inject("bookStore")(
  observer(({ test, bookStore }) => {
    const [open, setOpen] = React.useState(false);
    const [questions, setQuestions] = useState([]);
    const [passed, setPassed] = useState(false);
    const { t } = useTranslation();

    const allAnswered = useMemo(() => {
      console.log("questions:", questions);
      return questions.filter((q) => q.hasOwnProperty("answer")).length;
    }, [questions]);

    const handleTestState = useCallback(() => {
      setPassed(true);
    }, []);

    const handleOpen = () => {
      setQuestions(test.questions.slice());
      setOpen(true);
      setPassed(false);
    };
    const handleClose = () => {
      setOpen(false);
      setPassed(false);
      setQuestions([]);
    };

    const handleAnswer = useCallback(
      (answer, question) => {
        const q = questions.find((q) => q._id === question);
        const index = questions.indexOf(q);
        if (index) {
          setQuestions((prev) => {
            const newState = prev.map((q) => {
              if (q._id === question._id) {
                return {
                  ...q,
                  answer,
                };
              }
              return q;
            });
            return newState;
          });
        }
      },
      [questions]
    );

    const renderTest = useCallback(
      (question, index) => {
        switch (test.testType) {
          case "POLL": {
            return (
              <SliderTest
                onAnswer={handleAnswer}
                question={question}
                index={index + 1}
              />
            );
          }
          case "TEST": {
            return (
              <TestTest
                onAnswer={handleAnswer}
                question={question}
                index={index + 1}
              />
            );
          }
          case "FREE_ANSWER": {
            return (
              <AnswerTest
                onAnswer={handleAnswer}
                question={question}
                index={index + 1}
              />
            );
          }
          default: {
            return null;
          }
        }
      },
      [handleAnswer, test]
    );

    useEffect(() => {
      setQuestions(test.questions.slice());
    }, [test]);

    return (
      <>
        {test && (
          <>
            <p className="mr-20">{t("toPassTest")}</p>
            <button onClick={handleOpen} className="default-button yellow">
              {t("passTest")}
            </button>
          </>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          style={{
            paddingBottom: 0,
          }}
        >
          <Box
            sx={{
              ...style,
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              padding: 1,
              overflow: "scroll",
              height: "100dvh",
            }}
          >
            {passed ? (
              <TestResult
                onClose={handleClose}
                test={test}
                questions={questions}
              />
            ) : (
              <div className="modal-box">
                <div className="relative">
                  <h2 className="child-modal-title">{test.name}</h2>

                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  >
                    <img src={IcCloseMenu} />
                  </div>
                </div>
                <div className="asdlo-ads">
                  <p className="child-modal-description">{test.description}</p>
                  <FormGroup
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="questions-wrap">
                      {test.questions.map((question, index) =>
                        renderTest(question, index)
                      )}
                    </div>
                  </FormGroup>
                </div>
                <button
                  disabled={allAnswered !== test.questions.length}
                  onClick={handleTestState}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: isMobile ? 20 : 60,
                  }}
                  className="default-button yellow big-padding"
                >
                  <p>{t("endTest")}</p>
                </button>
              </div>
            )}
          </Box>
        </Modal>
      </>
    );
  })
);
