import React from "react";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 not-found ">
      <p>{t("notFound")}</p>
    </div>
  );
};
