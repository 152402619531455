import { useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IcLogo, IcFb, IcVk, IcInstagram } from "assets";
import privatePolicy from "assets/privatePolicy.pdf?url";
import paymentPolicy from "assets/paymentPolicy.pdf?url";
import AnchorLink from "react-anchor-link-smooth-scroll";

import "styles/Layout.css";
import { Logo } from "./Logo";
import { observer, inject } from "mobx-react";

export const Footer = inject("bookStore")(
  observer((props) => {
    const isFB = useMemo(() => {
      return props.bookStore.social.find((soc) => soc.name === "fb");
    }, [props.bookStore.social]);
    const isInst = useMemo(() => {
      return props.bookStore.social.find((soc) => soc.name === "inst");
    }, [props.bookStore.social]);

    const isVk = useMemo(() => {
      return props.bookStore.social.find((soc) => soc.name === "vk");
    }, [props.bookStore.social]);
    const { t } = useTranslation();

    const handleAboutUs = useCallback(() => {
      const element = document.getElementById("about-us");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, []);

    return (
      <div className="footer">
        <div className="container mx-auto flex flex-row justify-between items-center">
          <div className="header-left flex flex-row">
            <Logo />
          </div>
          <div className="header-center flex flex-row justify-around">
            {isInst && (
              <Link target="_blank" to={isInst.link}>
                <img className="ic-social" src={IcInstagram} alt="instagram" />
              </Link>
            )}

            {isFB && (
              <Link target="_blank" to={isFB.link} className="mx-10">
                <img className="ic-social" src={IcFb} alt="fb" />
              </Link>
            )}

            {isVk && (
              <Link target="_blank" to={isVk.link}>
                <img className="ic-social" src={IcVk} alt="vk" />
              </Link>
            )}
          </div>
          <div className="hidden header-left xl:flex flex-col justify-end  items-end">
            <Link to={"/catalog"}>
              <p className="footer-link ">{t("catalog")}</p>
            </Link>
            <Link to={"/#about-us"} onClick={handleAboutUs}>
              <p className="footer-link"> {t("aboutProject")}</p>
            </Link>
            <Link target="_blank" to={privatePolicy}>
              <p className="footer-link"> {t("privatePolicy")}</p>
            </Link>
            <Link target="_blank" to={paymentPolicy}>
              <p className="footer-link"> {t("paymentPolicy")}</p>
            </Link>
          </div>
        </div>
        <div className="xl:hidden block flex flex-col mt-5 mb-5 items-start justify-start">
          <Link className="mb-1" target="_blank" to={privatePolicy}>
            <p className="footer-link"> {t("privatePolicy")}</p>
          </Link>
          <Link target="_blank" to={paymentPolicy}>
            <p className="footer-link"> {t("paymentPolicy")}</p>
          </Link>
        </div>
      </div>
    );
  })
);
