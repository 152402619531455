import React, { useEffect, useState } from "react";
import { IcFb, IcInstagram, IcVk } from "assets";
import { Link } from "react-router-dom";

export const SocialIcon = (props) => {
  const [image, setImage] = useState();

  useEffect(() => {
    switch (props.social) {
      case "instagram": {
        setImage(IcInstagram);
        return;
      }
      case "facebook": {
        setImage(IcFb);
        break;
      }
      case "vk": {
        setImage(IcVk);
        break;
      }
    }
  }, []);

  return (
    <Link className="mr-8" to={String(props.social)}>
      <img src={image} />
    </Link>
  );
};
