import React, { useRef, useState, useCallback, useMemo } from "react";
import { ReactReader, ReactReaderStyle } from "react-reader";
import { Tooltip } from "react-tooltip";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import stringSimilarity from "string-similarity";
import {
  IcArrowLeft,
  IcBookSettings,
  IcBookmark,
  IcBookmarkList,
  IcCloseMenu,
  IcDeletePin,
  IcFavoriteBook,
  IcFullPage,
} from "assets";
import ReactStars from "react-stars";
import { ReactEpubViewer } from "react-epub-viewer";

import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { CONFIGS } from "utils/configs";
import { useEffect } from "react";
import CFI from "epub-cfi-resolver";
import { compareCFIInRange } from "utils/compare";
import { TestModal } from "components/TestModal";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slider,
  TextField,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { YellowButton } from "components/YellowButton";

const readerStyles = {
  ...ReactReaderStyle,
  arrow: {
    ...ReactReaderStyle.arrow,
  },
  tocAreaButton: {
    ...ReactReaderStyle.tocAreaButton,
    color: "#000",
    fontSize: 12,
  },
};

const style = {
  position: "absolute",
  bgcolor: "#EFF3F7",
  boxShadow: 24,
  height: "100vh",
  pt: 2,
  px: 4,
};

export const BookReader = inject(
  "bookStore",
  "authStore"
)(
  observer((props) => {
    const { t } = useTranslation();

    const params = useParams();
    const [searchParams] = useSearchParams();

    const { bookStore, authStore } = props;
    const readerRef = useRef(null);
    const tocRef = useRef(null);
    const renditionRef = useRef(null);
    const navigate = useNavigate();
    const goBack = () => {
      navigate(-1);
    };

    const [reviewText, setReviewText] = useState("");
    const [location, setLocation] = useState(null);
    const [size, setSize] = useState(16);
    const [open, setOpen] = useState(false);
    const [reviewOpen, setReviewOpen] = useState(false);

    const [settings, setSettings] = useState(false);

    const [page, setPage] = useState("");
    const [cfi, setCfi] = useState("");
    const [selections, setSelections] = useState([]);
    const [bookmarks, setBookmarks] = useState(false);
    const [name, setName] = useState("");

    const [rating, setRating] = useState(0);

    const isOwned = useMemo(() => {
      const bookId = searchParams.get("id");
      return (
        props.authStore.profile?.books_owned?.filter((fav) => {
          return fav?.book?._id === bookId;
        })?.length > 0
      );
    }, [props.authStore.profile?.books_owned, searchParams]);

    const savedBookmarks = useMemo(() => {
      if (authStore.profile) {
        return authStore.profile?.bookMarks.find(
          (b) => b?.book === searchParams.get("id")
        );
      }
      return [];
    }, [authStore.profile, searchParams]);

    const [firstRenderDone, setFirstRenderDone] = useState(false);
    const [test, setTest] = useState(null);

    useEffect(() => {
      if (bookStore.book && location && renditionRef) {
        const filteredTests = bookStore.book?.test?.filter((test) => {
          console.log(
            test.cfiCode,
            renditionRef.current.location.start.cfi,
            renditionRef.current.location.end.cfi
          );
          const inside = compareCFIInRange(
            renditionRef.current.location.start.cfi,
            renditionRef.current.location.end.cfi,
            test?.cfiCode
          );

          return inside ? test : null;
        });

        setTest(filteredTests);
      } else {
        setTest(null);
      }
    }, [location]);

    const handleCheckForTest = (rendition) => {
      console.log("rendition:", rendition);
      if (bookStore.book && location && rendition) {
        const filteredTests = bookStore.book?.test?.filter((test) => {
          const inside = compareCFIInRange(
            rendition.current.location.start.cfi,
            rendition.current.location.end.cfi,
            test?.cfiCode
          );

          return inside ? test : null;
        });

        setTest(filteredTests);
      } else {
        setTest(null);
      }
    };

    const locationChanged = useCallback(
      (epubcifi) => {
        console.log(
          "Location changed:",
          epubcifi,
          "First render done:",
          firstRenderDone
        );
        if (!firstRenderDone) {
          const page =
            searchParams.get("page") ||
            authStore.profile?.books_owned?.find(
              (book) => book?.book?._id === bookStore?.book?._id
            )?.page;

          if (page) {
            setCfi(page);

            setLocation(page);

            setTimeout(() => {
              readerRef.current.next();
            }, 250);
            setTimeout(() => {
              readerRef.current.prev();
            }, 400);
          }

          setFirstRenderDone(true);
        } else {
          if (renditionRef.current) {
            const { displayed, href } = renditionRef.current.location.start;
            const chapter = tocRef.current?.find((item) => item.href === href);
            setPage(
              `Page ${displayed.page} of ${displayed.total} in chapter ${
                chapter ? chapter.label : "n/a"
              }`
            );
            localStorage.setItem("book-progress", epubcifi);
            bookStore.saveBookPage(epubcifi);
            setCfi(epubcifi);
            setLocation(epubcifi);
          }
        }
      },
      [
        authStore,
        bookStore,
        firstRenderDone,
        searchParams,
        renditionRef,
        readerRef,
      ]
    );

    const handleBook = useCallback(() => {
      props.bookStore.fetchBook(searchParams.get("id"));
    }, [props.bookStore, searchParams]);

    useEffect(() => {
      authStore.getProfile();
      handleBook();
    }, []);

    useEffect(() => {
      if (authStore.profile) {
        if (!isOwned) {
          navigate("/");
        }
      }
    }, [authStore.profile, isOwned]);

    useEffect(() => {
      if (renditionRef.current) {
        function setRenderSelection(cfiRange, contents) {
          setSelections(
            selections.concat({
              text: renditionRef.current.getRange(cfiRange).toString(),
              cfiRange,
            })
          );
          renditionRef.current.annotations.add(
            "highlight",
            cfiRange,
            {},
            null,
            "hl",
            {
              fill: "gray",
              "fill-opacity": "0.5",
              "mix-blend-mode": "multiply",
            }
          );
          setTimeout(() => {
            renditionRef.current?.annotations.remove(cfiRange, "highlight");
          }, 4000);

          contents.window.getSelection().removeAllRanges();
        }
        renditionRef.current.on("selected", setRenderSelection);
        return () => {
          renditionRef.current.off("selected", setRenderSelection);
        };
      }
    }, [setSelections, selections]);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleReviewOpen = () => {
      setReviewOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setSettings(false);
      setReviewOpen(false);
    };

    const handleSendReview = () => {
      props.bookStore.addReview({
        rating,
        text: reviewText,
      });
      setRating(0);
      setReviewOpen(false);
      setReviewText("");
    };

    const handleRemoveBookmark = useCallback(
      (mark) => {
        bookStore.removeBookMark(mark._id, () => {
          authStore.getProfile();
        });
      },
      [authStore, bookStore]
    );

    const handleFullScreen = useCallback(() => {
      const elem = document.documentElement;

      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      }
    }, []);

    useEffect(() => {
      renditionRef.current?.themes.fontSize(`${size}px`);
    }, [size]);

    if (authStore.profile === null || !bookStore.book) {
      return <div>...loading</div>;
    }

    return (
      <div className="flex flex-col vh-100">
        <div className="flex reader-header justify-between">
          <button onClick={goBack}>
            <img alt="arrow-left" src={IcArrowLeft} />
          </button>
          <div className="flex flex-col aie z-50">
            <Tooltip zIndex={100} id="my-tooltip" hidden={isMobile} />
            <div className="flex">
              <button
                style={{
                  backgroundColor: "#213669",
                  borderRadius: 10,
                  color: "#fff",
                  padding: "7px 14px",
                  fontSize: 12,
                }}
                className="mr-8"
                onClick={handleReviewOpen}
              >
                <p>Оставить отзыв</p>
              </button>
              <button
                onClick={() => {
                  readerRef.current.toggleToc();
                }}
                className="mr-8"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Открыть главы"
              >
                <img alt="bookmarkList" src={IcBookmarkList} />
              </button>
              <button
                onClick={() => {
                  if (cfi.length > 0) {
                    handleClickOpen();
                  }
                }}
                className="mr-8"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Добавить закладку"
              >
                <img alt="favorite" src={IcFavoriteBook} />
              </button>
              <button
                onClick={() => {
                  setBookmarks((prev) => !prev);
                }}
                className="mr-8"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Закладки"
              >
                <img alt="bookmark" src={IcBookmark} />
              </button>
              {/* <button
                onClick={() => {
                  setSettings(true);
                }}
                className="mr-8"
              >
                <img alt="bookSettings" src={IcBookSettings} />
              </button> */}

              <button
                onClick={handleFullScreen}
                className="mr-8 d-sm-none"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="На весь экран"
              >
                <img alt="fullPage" src={IcFullPage} />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
            }}
          >
            <div className="flex flex-1 flex-col">
              {/* {bookStore?.book && ( */}
              <ReactReader
                ref={readerRef}
                location={location}
                // swipeable
                readerStyles={readerStyles}
                locationChanged={locationChanged}
                epubInitOptions={{}}
                epubOptions={{
                  allowPopups: true,
                }}
                handleTextSelected={(cfi, contents) => {
                  setCfi(cfi);
                }}
                getRendition={(rendition) => {
                  rendition.on("rendered", (section, view) => {
                    const viewDocument = view.document;
                    renditionRef.current = rendition;
                    handleCheckForTest(rendition);
                    renditionRef.current.themes.default({
                      "::selection": {
                        background: "gray",
                      },
                      "::selected": {
                        background: "transparent",
                      },
                    });

                    rendition.hooks.content.register((contents) => {
                      const body =
                        contents.window.document.querySelector("body");
                      if (body) {
                        body.oncontextmenu = () => {
                          return false;
                        };
                      }
                    });
                    // renditionRef.current?.themes.fontSize(`${size}px`);

                    setSelections([]);
                  });
                }}
                tocChanged={(toc) => (tocRef.current = toc)}
                url={`${CONFIGS.BASE_API}${bookStore.book.book}`}
                // url={`${"https://admin.senimen.com/api"}${bookStore.book.book}`}
              />
            </div>
            {bookmarks && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: isMobile ? "100vw" : "30vw",
                  position: "absolute",
                  right: 0,
                  height: "calc(100vh - 70px)",
                  zIndex: 100,
                  backgroundColor: "#fff",
                  top: isMobile ? 0 : "70px",
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  gap: 20,
                  padding: 20,
                  boxShadow: "-7px 4px 8px 0px rgba(34, 60, 80, 0.2)",
                }}
              >
                <div
                  style={{
                    marginBottom: 20,
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  className="flex "
                >
                  <Typography fontSize={24} fontWeight={"700"}>
                    {t("bookmarks")}
                  </Typography>
                  <div onClick={() => setBookmarks(null)}>
                    <img src={IcCloseMenu} />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    alignItems: "flex-start",
                    flex: 1,
                    overflow: "scroll",
                    width: "100%",
                  }}
                >
                  {savedBookmarks?.marks.map((mark) => {
                    return (
                      <Button
                        onClick={() => {
                          setLocation(mark.cfi);
                          setBookmarks(false);
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Typography fontSize={14} color={"#010101"}>
                          {mark?.name}
                        </Typography>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveBookmark(mark);
                          }}
                          style={{
                            position: "absolute",
                            right: 0,
                          }}
                        >
                          <img src={IcDeletePin} />
                        </div>
                      </Button>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="test-toast">
            {test?.length > 0 && <TestModal test={test[0]} />}

            <div
              style={{
                position: "absolute",
                right: 20,
                bottom: 20,
                fontSize: 12,
              }}
              className="holter d-sm-none"
            >
              <span>{cfi}</span>
            </div>
          </div>
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle fontSize={16}>{t("saveBookMark")}</DialogTitle>
          <DialogContent gap={8}>
            <DialogContentText marginBottom={2} fontSize={16}>
              Чтобы сохранить закладку напишите ее название
            </DialogContentText>

            <input
              autoFocus
              className="w-full text-2xl outline-none border-b-2"
              placeholder={t("bookmarkName")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                fontSize: 14,
              }}
              onClick={handleClose}
            >
              {t("cancel")}
            </Button>
            <Button
              style={{
                fontSize: 14,
              }}
              onClick={() => {
                props.bookStore.addBookMark(
                  {
                    cfi,
                    name,
                  },
                  () => {
                    authStore.getProfile();
                  }
                );
                setName("");
                setSelections([]);
                setCfi("");
                handleClose();
              }}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth open={settings} onClose={handleClose}>
          <DialogTitle fontSize={16}>{t("settings")}</DialogTitle>
          <DialogContent>
            <DialogContentText fontSize={18}>
              {t("changeFontSize")}
            </DialogContentText>
            <Slider
              aria-label="Temperature"
              getAriaValueText={""}
              valueLabelDisplay="auto"
              step={2}
              marks
              value={size}
              min={10}
              max={30}
              onChange={(e) => {
                setSize(e.target.value);
              }}
            />
          </DialogContent>
        </Dialog>

        <Modal
          open={reviewOpen}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          style={{
            paddingBottom: 0,
          }}
        >
          <Box
            sx={{
              ...style,
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              padding: 1,
              overflow: "scroll",
              height: "100vh",
            }}
          >
            <div className="modal-box">
              <div className="relative">
                <h2 className="child-modal-title">{t("postReview")}</h2>

                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                >
                  <img src={IcCloseMenu} />
                </div>
              </div>
              <p className="child-modal-description">{t("shareThoughts")}</p>

              <div
                className="flex flex-col gap-8 p-4 mb-4 "
                style={{
                  backgroundColor: "#EFF3F7",
                  borderRadius: 10,
                  padding: 16,
                }}
              >
                <p
                  style={{
                    borderRadius: 10,
                    fontSize: 14,
                  }}
                >
                  {t("Оцените книгу")}
                </p>

                <ReactStars
                  count={5}
                  value={rating}
                  onChange={(rating) => setRating(rating)}
                  size={24}
                  color2={"#ffd700"}
                />
              </div>

              <div
                className="flex flex-col gap-8 p-4 "
                style={{
                  backgroundColor: "#EFF3F7",
                  borderRadius: 10,
                  padding: 16,
                }}
              >
                <p
                  style={{
                    borderRadius: 10,
                    fontSize: 14,
                  }}
                >
                  {t("yourAnswer")}
                </p>

                <textarea
                  style={{
                    borderRadius: 10,
                    height: 300,
                    padding: 16,
                    fontSize: 14,
                  }}
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                ></textarea>
              </div>
              <div className="flex items-center justify-center">
                <button
                  disabled={rating < 1 || reviewText.trim().length < 1}
                  onClick={handleSendReview}
                  className="default-button yellow"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 16,
                    paddingTop: 16,
                    paddingBottom: 16,
                  }}
                >
                  <p>{t("sendReview")}</p>
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
  })
);
