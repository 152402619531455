import {
  IcBookSaved,
  IcFavorite,
  IcSettings,
  IcTest,
  IcArrowRight,
} from "assets";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ProfileButton } from "./ProfileButton";

export const ProfileCatalog = (props) => {
  const { selected } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { t } = useTranslation();

  const handleSelect = (category) => {
    props.onSelect(category);
  };

  const handleLogout = () => {
    props.onLogout();
  };

  return (
    <div>
      <h2 className="hidden xl:block grip-title mb-4 xl:mb-16">
        {t("privateCabinet")}
      </h2>
      <div className="cat-list">
        <ProfileButton
          onClick={handleSelect}
          selected={selected === 0}
          img={IcBookSaved}
          number={0}
          text={t("boughts")}
        />
        <ProfileButton
          onClick={handleSelect}
          selected={selected === 1}
          img={IcFavorite}
          number={1}
          text={t("favorites")}
        />
        <ProfileButton
          onClick={handleSelect}
          selected={selected === 2}
          img={IcSettings}
          number={2}
          text={t("settings")}
        />
        <ProfileButton
          onClick={handleSelect}
          selected={selected === 3}
          img={IcTest}
          number={3}
          text={t("myTests")}
        />
        <button onClick={handleClickOpen} className="logout-button">
          <p>{t("logout")}</p>
          <img src={IcArrowRight} />
        </button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Вы уверены что хотите выйти?
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>
            <span className="text-xl">Нет</span>
          </Button>
          <Button variant="outlined" color="error" onClick={handleLogout}>
            <span className="text-xl">Да</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
