import { Axios } from "utils/axios";
import Requests from "constants/requests";

export const signInApi = async (email, password) => {
  const response = await Axios.post(Requests.SIGN_IN, {
    email,
    password,
  });

  return response;
};

export const signUpApi = async (data) => {
  const response = await Axios.post(Requests.SING_UP, data);

  return response;
};

export const socialAuth = async (data) => {
  const response = await Axios.post(Requests.SOCIAL_AUTH, data);
  return response;
};

export const resetPasswordApi = async (email) => {
  const response = await Axios.post(Requests.RESET_PASSWORD, {
    email,
  });

  return response;
};

export const updateProfileApi = async (data) => {
  const response = await Axios.post(Requests.PROFILE_UPDATE, data);
  return response;
};

export const getProfileApi = async () => {
  const response = await Axios.get(Requests.PROFILE);
  return response;
};

export const getBooks = async (data) => {
  const response = await Axios.post(Requests.BOOKS, data);
  return response;
};

export const getAutors = async () => {
  const response = await Axios.get(Requests.AUTORS);
  return response;
};

export const getCategories = async () => {
  const response = await Axios.get(Requests.CATEGORIES);
  return response;
};

export const getMainBooks = async () => {
  const response = await Axios.post(Requests.BOOKS, {
    mainBooks: true,
  });
  return response;
};

export const getBookById = async (id) => {
  const response = await Axios.get(`${Requests.BOOK_ID}${id}`);

  return response;
};

export const postFavoriteApi = async (book_id) => {
  const response = await Axios.post(Requests.BOOK_FAVORITE, {
    book_id,
  });
  return response;
};

export const getSocial = async () => {
  const response = await Axios.get(Requests.SOCIAL);
  return response;
};

export const postBookPage = async (data) => {
  const response = await Axios.post(Requests.BOOK_PAGE, data);
  return response;
};

export const postTestResult = async (data) => {
  const response = await Axios.post(Requests.TEST_RESULT, data);
  return response;
};

export const postAddBookmark = async (data) => {
  const response = await Axios.post(Requests.ADD_BOOKMARK, data);
  return response;
};

export const postRemoveookmark = async (data) => {
  const response = await Axios.post(Requests.REMOVE_BOOKMARK, data);
  return response;
};

export const checkSocial = async (data) => {
  const response = await Axios.post(Requests.CHECK, data);

  return response;
};

export const postAddReview = async (data) => {
  const response = await Axios.post(
    Requests.ADD_REVIEW.replace(":id", data.book._id),
    data
  );
  return response;
};

export const activateEmail = async (data) => {
  const response = await Axios.post(Requests.ACTIVE_EMAIL, data);
  return response;
};

export const postDeleteAccount = async (data) => {
  const response = await Axios.post(Requests.DELETE_USER, data);
  return response;
};
