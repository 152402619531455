import { inject, observer } from "mobx-react";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateWrapper = inject("authStore")(
  observer((props) => {

    const isAuth = props.authStore.isAuthenticated;
    return isAuth ? <Outlet/> : <Navigate to="/" />;
  })
);
