import React, { useState, useEffect, useCallback, useMemo } from "react";
import { MenuNav } from "components/MenuNav";
import { useTranslation } from "react-i18next";

import { ProfileCatalog } from "components/ProfileCatalog";
import { BookGrid } from "components/BookGrid";
import { Settings } from "components/Settings";
import { inject, observer } from "mobx-react";
import { useSearchParams, useNavigate } from "react-router-dom";

export const Profile = inject(
  "authStore",
  "bookStore"
)(
  observer((props) => {
    let navigate = useNavigate();
  
    const { authStore } = props;
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const [routes, setRoutes] = useState([]);
    const [category, setCategory] = useState(0);

    const step = useMemo(() => {
      switch (category) {
        case 0: {
          return t("boughts");
        }
        case 1: {
          return t("favorites");
        }
        case 2: {
          return t("settings");
        }
        case 3: {
          return t("myTests");
        }
        default: {
          return t("settings");
        }
      }
    }, [category, t]);

    useEffect(() => {
      const newRoutes = [
        {
          name: t("main"),
          to: "/",
        },
        {
          name: t("privateCabinet"),
          to: "/profile",
        },
        {
          name: t(step),
          to: "/profile",
        },
      ];
      setRoutes(newRoutes);
    }, [step]);

    useEffect(() => {
      if (searchParams.get("cat") === "favorite") {
        setCategory(1);
      }
    }, [searchParams]);

    useEffect(() => {
      authStore.getProfile();
    }, []);

    const renderOutlet = useCallback(() => {
      // eslint-disable-next-line default-case
      switch (category) {
        case 0: {
          return <BookGrid items={authStore.profile?.books_owned} />;
        }
        case 1: {
          return <BookGrid items={authStore.profile?.favorites} />;
        }
        case 2: {
          return <Settings user={authStore.profile} />;
        }
        case 3: {
          return (
            <BookGrid type="test" items={authStore.profile?.test_passed} />
          );
        }
      }
    }, [authStore.profile, category]);

    const handleCategory = (category) => {
      setCategory(category);
    };

    return (
      <div className="section-padding">
        <div className="mb-10">
          <MenuNav routes={routes} />
        </div>
        <section>
          <div className="grid grid-cols-3 grid-row-2 xl:grid-cols-4 gap-10 xl:gap-32">
            <div className="col-span-3 xl:col-span-1 ">
              <ProfileCatalog
                onLogout={() => {
                  authStore.logout();
                  navigate("/");
                }}
                selected={category}
                onSelect={handleCategory}
              />
            </div>
            <div className="col-span-3">{renderOutlet()}</div>
          </div>
        </section>
      
      </div>
    );
  })
);
