import React from "react";

export const ProfileButton = (props) => {
  return (
    <button
      onClick={() => {
        props.onClick(props.number);
      }}
      className={`profile-button ${props.selected && "selected"}`}
    >
      <img className={"img"} src={props.img} />
      <p>{props.text}</p>
    </button>
  );
};
