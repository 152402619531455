import { IcArrowRight, ImgCreator } from "assets";
import { YellowButton } from "components/YellowButton";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pb-20">
      <section className="section-padding">
        <h2 className="section-title mb-3 xl:mb-15">{t("aboutProject")}</h2>
        <p className="about-desc mb-10 xl:mb-28">
          <span>Senimen</span> {t("projectInfo")}
        </p>
        <div className="hidden  xl:flex">
          <div className="flex-1 flex flex-col items-center justify-between ">
            <p className="creator-desc"> {t("whyWeCreate")}</p>
          </div>
          <div className="flex-1">
            <img className="creator-image" src={ImgCreator} />
          </div>
        </div>
        <div className="block xl:hidden">
          <div className="flex-1">
            <img className="creator-image" src={ImgCreator} />
          </div>
          <p className="creator-desc"> {t("whyWeCreate")}</p>
        </div>
      </section>
    </div>
  );
};
