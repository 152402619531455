import React from "react";
import { FilterItem } from "components/FilterItem";
import { useTranslation } from "react-i18next";
import { IcCloseMenu } from "assets";

const genres = [
  {
    name_ru: "Личная эффективность",
    id: 1,
    id: "efficency",
  },
  {
    name_ru: "Бизнес",
    id: 1,
    id: "business",
  },
  {
    name_ru: "Философия",
    id: 1,
    id: "philosofy",
  },
  {
    name_ru: "Отношения",
    id: 1,
    id: "relationships",
  },
];

export const FilterSidebar = (props) => {
  const {
    open,
    onClose,
    categories,
    authors,
    onCategories,
    onAuthor,
    onSearch,
  } = props;
  const { t } = useTranslation();
  return (
    <div
      className={`sidebar-filter xl:hidden  ${
        !open && "sidebar-closed-filter"
      }`}
    >
      <div className="flex flex-1  flex-col">
        <div className="flex flex-col items-end">
          <button onClick={onClose}>
            <img className="ic-close-menu" src={IcCloseMenu} />
          </button>
        </div>
        <div className="flex flex-1 flex-col justify-center">
          <div className="mb-16">
            <h4 className="filter-title mb-8">{t("genre")}</h4>
            {categories.map((genre) => {
              return <FilterItem onClick={onCategories} item={genre} />;
            }, [])}
          </div>
          <div>
            <h4 className="filter-title mb-8">{t("autor")}</h4>
            {authors.map((genre) => {
              return <FilterItem onClick={onAuthor} item={genre} />;
            }, [])}
          </div>
        </div>
        <div className="full-width">
          <button
            onClick={() => {
              onSearch();
              onClose();
            }}
            className="default-button yellow "
          >
            <p>{t("show")}</p>
          </button>
        </div>
      </div>
    </div>
  );
};
