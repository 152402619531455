import React from "react";
import { IcLanguage } from "assets";
import { useTranslation } from "react-i18next";

export const LanguageSelect = () => {
  const { t, i18n } = useTranslation();
  return (
    <button className="flex items-center mb-10">
      <img className="ic-language" src={IcLanguage} />
      <p className="language">{t(i18n.language)}</p>
    </button>
  );
};
