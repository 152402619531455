import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <h1 className="section-title mb-3">{t("pageNotFound")}</h1>
      <Link to={"/"}>
        <span className="creator-desc">{t("goToMainPage")}</span>
      </Link>
    </div>
  );
};
