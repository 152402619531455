import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IcFavorite, IcMenu, IcProfile } from "assets";
import "styles/Layout.css";
import { Logo } from "./Logo";
import { useCallback, useEffect, useState } from "react";

import { Authorization } from "./Authorization";
import { inject, observer } from "mobx-react";

export const Header = inject("authStore")(
  observer((props) => {
    const { t } = useTranslation();
    const { authStore } = props;
    const isAuth = props.authStore.isAuthenticated;

    const handleToggleSidebar = () => {
      props.onSidebar();
    };

    const handleAuthModalToggle = () => {
      props.onAuth();
    };

    const handleType = (type) => {
      props.onType(type);
    };

    const handleAboutUs = useCallback(() => {
      const element = document.getElementById("about-us");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, []);

    return (
      <div className="header ">
        <div className="container mx-auto  ">
          <div className="flex flex-row justify-between items-center header-padding">
            <div className="header-left flex flex-row">
              <Logo />
            </div>
            <div className="header-center flex-row  hidden xl:flex">
              <Link to={"/catalog"}>
                <p className="header-link">{t("catalog")}</p>
              </Link>
              <Link to={"/#about-us"} onClick={handleAboutUs}>
                <p className="header-link"> {t("aboutProject")}</p>
              </Link>
            </div>
            {isAuth ? (
              <div className=" flex-row justify-end hidden xl:flex  header-left">
                <Link to="/profile" className="flex flex-col items-center">
                  <img className="ic-profile" src={IcProfile} />
                  <p className="header-link">{authStore.profile?.fio}</p>
                </Link>
                <Link
                  to="/profile?cat=favorite"
                  className="flex flex-col items-center"
                >
                  <img className="ic-profile" src={IcFavorite} />
                  <p className="header-link">{t("favorites")}</p>
                </Link>
              </div>
            ) : (
              <div className=" flex-row justify-end hidden xl:flex  header-left">
                <button
                  onClick={() => {
                    handleType("signUp");
                    handleAuthModalToggle();
                  }}
                  className="default-button mr-12"
                >
                  <p>{t("register")}</p>
                </button>
                <button
                  onClick={() => {
                    handleType("signIn");
                    handleAuthModalToggle();
                  }}
                  className="default-button white"
                >
                  <p>{t("signIn")}</p>
                </button>
              </div>
            )}

            <button onClick={handleToggleSidebar} className="block xl:hidden">
              <img className="ic-menu" src={IcMenu} />
            </button>
          </div>
        </div>
      </div>
    );
  })
);
