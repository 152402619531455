import React from "react";
import "styles/Components.css";
import { Link } from "react-router-dom";

export const MenuNav = (props) => {
  const { routes } = props;
  return (
    <div className="flex">
      {routes.map((route, i) => {
        return (
          <div key={route.name} className="flex  items-center margin-12">
            <Link
              className={`menu-link ${
                i + 1 === routes.length && "nav-current"
              }`}
              to={route.to}
            >
              <p>{route.name}</p>
            </Link>

            {i + 1 !== routes.length && <div className="nav-circle" />}
          </div>
        );
      })}
    </div>
  );
};
