import { makeObservable, observable, action } from "mobx";
import axios from "axios";
import {
  getAutors,
  getBooks,
  getCategories,
  getMainBooks,
  getBookById,
  getSocial,
  postBookPage,
  postTestResult,
  postAddBookmark,
  postAddReview,
  postRemoveookmark,
} from "api";

export default class BookStore {
  books = [];
  weRecomend = [];
  nowReading = [];
  authors = [];
  categories = [];
  book = null;
  author = {};
  banner = [];
  loading = {
    book: true,
    author: true,
    books: false,
    authors: false,
    categories: false,
    nowReading: false,
    weRecomend: false,
    banner: false,
  };
  social = [];
  error = null;
  showModal = false;
  modalType = "";

  constructor(rootStore) {
    makeObservable(this, {
      books: observable,
      book: observable,
      author: observable,
      authors: observable,
      categories: observable,
      weRecomend: observable,
      nowReading: observable,
      loading: observable,
      error: observable,
      social: observable,
      fetchBooks: action,
      createBook: action,
      deleteBook: action,
      fetchAutors: action,
      fetchCategories: action,
      saveBookPage: action,
      showModal: observable,
      modalType: observable,
    });
    // this.fetchAll();

    // const book = localStorage.getItem("book");
    // if (book) {
    //   this.book = JSON.parse(book);
    // }
  }

  fetchAll = () => {
    this.fetchBooks();
    this.fetchAutors();
    this.fetchCategories();
    this.fetchSocial();
  };

  fetchSocial = async () => {
    try {
      const response = await getSocial();
      this.social = response.data;
    } catch (error) {
      this.error = error;
    }
  };

  fetchBooks = async (category, author, page) => {
    try {
      this.loading.books = true;
      const data = {
        category,
        author: author,
        page,
      };
      const response = await getBooks(data);
      const books = response.data.map((book) => ({
        ...book,
      }));
      this.books = [...this.books, ...books];
      return this.books;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading.books = false;
    }
  };

  fetchAutors = async () => {
    try {
      this.loading.autors = true;
      const response = await getAutors();
      this.authors = response.data.authors;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading.authors = false;
    }
  };

  fetchCategories = async () => {
    try {
      this.loading.categories = true;
      const response = await getCategories();
      this.categories = response.data.categories;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading.categories = false;
    }
  };

  fetchMain = async () => {
    try {
      this.loading.weRecomend = true;
      this.loading.nowReading = true;

      const response = await getMainBooks();
      this.weRecomend = response.data.weRecomend.map((book) => ({
        ...book,
      }));
      this.nowReading = response.data.nowReading.map((book) => ({
        ...book,
      }));
      this.banner = response.data.banner;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading.categories = false;
      this.loading.banner = false;
    }
  };

  fetchBook = async (id) => {
    try {
      this.loading.book = true;
      const response = await getBookById(id);
      this.book = response.data;
      // localStorage.setItem("book", JSON.stringify(response.data));
    } catch (e) {
    } finally {
      this.loading.book = false;
    }
  };

  async createBook(book) {
    try {
      const response = await axios.post("/api/books", book);
      this.books.push(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async deleteBook(id) {
    try {
      await axios.delete(`/api/books/${id}`);
      this.books = this.books.filter((book) => book._id !== id);
    } catch (error) {
      console.error(error);
    }
  }

  async saveBookPage(cfi) {
    try {
      const data = {
        cfi,
        bookId: this.book._id,
      };
      const response = await postBookPage(data);
    } catch (error) {}
  }

  async saveTestResult(result, test) {
    try {
      const data = {
        result,
        test,
        bookId: this.book._id,
      };
      const response = await postTestResult(data);
    } catch (e) {}
  }

  async handleModal(value) {
    try {
      this.showModal = value;
      this.modalType = "signUp";
    } catch (error) {}
  }

  async addBookMark(cfi, onSuccess) {
    try {
      const data = {
        mark: cfi,
        bookId: this.book._id,
      };
      const response = await postAddBookmark(data);
      if (response.status >= 200 && response.status <= 300) {
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {}
  }

  async removeBookMark(markId, onSuccess) {
    try {
      const data = {
        mark: markId,
        bookId: this.book._id,
      };
      const response = await postRemoveookmark(data);
      if (response.status >= 200 && response.status <= 300) {
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {}
  }

  async addReview({ text, rating }) {
    try {
      const data = {
        book: this.book,
        text,
        rating,
      };
      const response = await postAddReview(data);
      console.log(response);
    } catch (e) {}
  }
}
