import { makeAutoObservable } from "mobx";
import AuthStore from "./authStore";
import BookStore from "./bookStore";

class RootStore {
  authStore = null;
  bookStore = null;
  isInitialized = false;

  constructor() {
    makeAutoObservable(this);
    this.authStore = new AuthStore();
    this.bookStore = new BookStore();
    Promise.all([this.initAuthStore(), this.initBookStore()]).then(() => {
      this.isInitialized = true;
    });
  }

  async initAuthStore() {
    // wait for data from local storage before initializing the auth store
    await new Promise((resolve) => setTimeout(resolve, 0));

    this.authStore = new AuthStore();
  }

  async initBookStore() {
    // wait for data from local storage before initializing the book store
    await new Promise((resolve) => setTimeout(resolve, 0));
    this.bookStore = new BookStore();
  }
}

const rootStore = new RootStore();

export default rootStore;
