import { Slider } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

function valuetext(value) {
  return `${value}°C`;
}

export const SliderTest = ({ index, question, onAnswer }) => {
  const { t } = useTranslation();

  useEffect(() => {
    onAnswer(3, question);
  }, []);
  
  return (
    <div className="question-sec">
      <span className="q-sc-i">
        {t("question")} {index}
      </span>
      <article className="q-sc-q">{question.question}</article>
      <div className="slider-wrap">
        <Slider
          aria-label="Always visible"
          defaultValue={3}
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          step={1}
          onChange={(value, newValue) => onAnswer(newValue, question)}
          marks={marks}
          min={1}
          max={5}
        />
      </div>
    </div>
  );
};
