import {
  IcArrowLeft,
  IcCloseMenu,
  IcEyeClose,
  IcEyeOpen,
  IcGoogle,
  IcVkSocial,
} from "assets";
import { inject, observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InputField } from "./InputField";
import { SocialButton } from "./SocialButton";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { makeid } from "utils/encription";
import { useGoogleLogin } from "@react-oauth/google";
import { Icon } from "@mui/material";

export const Authorization = inject(
  "authStore",
  "bookStore"
)(
  observer((props) => {
    const { authStore } = props;

    const { t } = useTranslation();

    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordCVisible, setPasswordCVisible] = useState(false);

    const [form, setForm] = useState({
      email: "",
      password: "",
      fio: "",
      passwordConfirm: "",
    });
    const [valid, setValid] = useState({
      email: true,
      password: true,
      fio: true,
      passwordConfirm: true,
    });
    const [type, setType] = useState("");
    const [authError, setAuthError] = useState("");
    const isSignIn = type === "signIn";

    useEffect(() => {
      setType(props.type);
    }, [props.type]);

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validatePassworrd = (password) => {
      return String(password).length > 5;
    };

    useEffect(() => {
      setAuthError(null);
    }, [type, isPasswordReset]);

    useEffect(() => {
      setValid({
        email: true,
        password: true,
        fio: true,
        passwordConfirm: true,
      });
    }, [isPasswordReset, isSignIn]);

    const handleChange = (event) => {
      setForm({
        ...form,
        [event.target.id]: event.target.value,
      });
      switch (event.target.id) {
        case "fio": {
          const v = event.target.value.length > 0;
          setValid({
            ...valid,
            [event.target.id]: v,
          });
          return;
        }
        case "email": {
          const v = validateEmail(event.target.value);

          setValid({
            ...valid,
            [event.target.id]: v ? true : false,
          });
          return;
        }
        case "password": {
          const v = validatePassworrd(event.target.value);
          setValid({
            ...valid,
            [event.target.id]: v ? true : false,
          });
          const passwordMatch = event.target.value === form.passwordConfirm;
          setValid((prev) => ({ ...prev, passwordConfirm: passwordMatch }));

          return;
        }
        case "passwordConfirm": {
          const v = form.password === event.target.value;
          setValid({
            ...valid,
            [event.target.id]: v,
          });

          return;
        }
        default: {
          return;
        }
      }
    };

    const handleAuth = () => {
      if (isPasswordReset) {
        authStore.resetPassword(form.email, () => {
          setIsPasswordReset(false);
        });
      } else {
        const allChecks = Object.values(valid).every((k) => k === true);
        if (isSignIn && valid.email && valid.password) {
          authStore.login(
            form.email,
            form.password,
            handleClearFields,
            handleError
          );
        } else if (allChecks) {
          authStore.register(form, handleClearFields, handleError);
        }
      }
    };

    const handleGoogleLogin = useGoogleLogin({
      onSuccess: (credentialResponse, tokenResponse) =>
        console.log(tokenResponse, credentialResponse),
    });

    const handleVkLogin = () => {
      window.open(
        "https://oauth.vk.com/authorize?client_id=51766675&redirect_uri=https://senimen.com/vk&scope=4194304&display=mobile&response_type=token"
      );
    };

    const handleClearFields = useCallback(() => {
      setForm({
        fio: "",
        email: "",
        password: "",
        passwordConfirm: "",
      });
      googleLogout();
      props.onClose();
    }, [props]);

    const handleSocial = useCallback(
      (credentialResponse) => {
        const user = jwtDecode(credentialResponse.credential);
        console.log(user);
        const data = {
          email: user.email,
          fio: user.family_name + " " + user.given_name,
          password: makeid(9),
          provider: "GOOGLE",
        };
        authStore.authSocial(data, handleClearFields);
      },
      [authStore, handleClearFields]
    );

    const handleError = (error) => {
      setAuthError(error.response.data.message);
    };

    const renderField = (name, type, options) => {
      return (
        <div className="polca">
          <label
            className={`input-field-wrap ${valid[name] === false && "error"}`}
          >
            <p>{t(name)}</p>
            <div className="flex ">
              <input
                id={name}
                value={form[name]}
                className="flex flex-1"
                type={options?.visible ? "text" : type || "text"}
                // placeholder={t(name)}
                onChange={(e) => handleChange(e)}
                {...options}
                onClick={() => {}}
              />
              {name.includes("password") && (
                <div onClick={options.onClick}>
                  <img
                    style={{
                      width: 20,
                      height: 20,
                      cursor: "pointer",
                    }}
                    src={options?.visible ? IcEyeOpen : IcEyeClose}
                  />
                </div>
              )}
            </div>
          </label>
          <p className="error-text">{!valid[name] && t(`error_${name}`)}</p>
        </div>
      );
    };

    return (
      <div className="shade-wrap">
        <div className="auth-modal-wrap">
          <div
            className={
              isPasswordReset
                ? "flex justify-between mb-2"
                : `flex justify-end mb-2`
            }
          >
            {isPasswordReset && (
              <button onClick={() => setIsPasswordReset(false)}>
                <img src={IcArrowLeft} />
              </button>
            )}
            <button onClick={props.onClose}>
              <img src={IcCloseMenu} />
            </button>
          </div>

          {!isPasswordReset && (
            <div className="auth-type-select-wrap">
              <button
                onClick={() => setType("signIn")}
                className={`auth-type-select ${isSignIn && "selected"}`}
              >
                <p className={`auth-type-label`}>{t("signIn")}</p>
              </button>
              <button
                onClick={() => setType("register")}
                className={`auth-type-select ${!isSignIn && "selected"}`}
              >
                <p className={`auth-type-label`}>{t("register")}</p>
              </button>
            </div>
          )}

          {!isPasswordReset && (
            <div className="mb-4">
              <p className="authorization-label">{t("enterFromAccount")}</p>
              <div className="flex flex-col relative">
                <div className="relative flex flex-col">
                  <div className="google-login-btn">
                    <GoogleLogin
                      size="large"
                      width={"340px"}
                      onSuccess={(credentialResponse) => {
                        console.log(credentialResponse);
                        handleSocial(credentialResponse);
                      }}
                      // useOneTap
                      onError={() => {
                        console.log("Login Failed");
                      }}
                    />
                  </div>
                  <SocialButton image={IcGoogle} text={"Google"} />
                </div>
                <SocialButton
                  image={IcVkSocial}
                  text={"VK"}
                  onClick={handleVkLogin}
                />
              </div>
            </div>
          )}

          <div>
            <p className="authorization-label">{t("fillFields")}</p>
            {!isPasswordReset ? (
              <form>
                {!isSignIn &&
                  renderField("fio", "text", {
                    maxLength: 20,
                  })}
                {renderField("email", "email")}
                {renderField("password", "password", {
                  maxLength: 20,
                  visible: passwordVisible,
                  onClick: () => setPasswordVisible((prev) => !prev),
                })}
                {!isSignIn &&
                  renderField("passwordConfirm", "password", {
                    maxLength: 20,
                    visible: passwordCVisible,
                    onClick: () => setPasswordCVisible((prev) => !prev),
                  })}
              </form>
            ) : (
              <form>{renderField("email", "email")}</form>
            )}
          </div>
          <div className="flex items-center justify-center">
            <p className="error-text">{t(authError)}</p>
          </div>
          <div className="flex flex-col mb-4">
            {!isPasswordReset && !isSignIn && (
              <span
                className="my-2"
                style={{
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                При регистрации вы соглашаетесь с{" "}
                <a
                  style={{
                    textDecoration: "underline",
                  }}
                  target="_blank"
                  href="https://senimen.com/static/media/privatePolicy.c1ac1628242c097be0d3.pdf"
                >
                  политикой конфиденциальности
                </a>
              </span>
            )}
            <button
              onClick={handleAuth}
              className="default-button yellow center "
            >
              <p>
                {isPasswordReset
                  ? t("reset")
                  : isSignIn
                  ? t("enter")
                  : t("register")}
              </p>
            </button>
          </div>
          {!isPasswordReset && isSignIn && (
            <div
              onClick={() => {
                setIsPasswordReset(true);
              }}
              style={{
                cursor: "pointer",
              }}
              className="flex flex-col"
            >
              <span
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              >
                {t("forgotPassword")}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  })
);
