import { Slider } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

export const TestTest = ({ index, question, onAnswer }) => {
  const { t } = useTranslation();
  const [answers, setAnswers] = useState([]);
  const [selected, setAnswer] = useState(null);

  const handleAnswer = useCallback(
    (newAnswer) => {
      if (newAnswer?._id === selected?._id) {
        setAnswer(null);
      } else {
        setAnswer(newAnswer);
      }
      onAnswer(newAnswer.weight, question);
    },
    [onAnswer, question, selected]
  );

  const handleAnswers = useCallback(
    (answer) => {
      let ss = answers;
      const objWithIdIndex = answers.findIndex((obj) => obj._id === answer._id);
      if (objWithIdIndex > -1) {
        ss.splice(objWithIdIndex, 1);
        setAnswers(ss);
      } else {
        ss.push(answer);
        setAnswers(ss);
      }
      const count = ss.reduce((a, b) => a + b.weight, 0);
      onAnswer(count, question);
    },
    [answers, onAnswer, question]
  );

  useEffect(() => {
    // onAnswer(0, question);
  }, []);

  return (
    <div className="question-sec">
      <span className="q-sc-i">
        {t("question")} {index}
      </span>
      <article className="q-sc-q">{question.question}</article>
      <div className="poll-wrap">
        {question.answers.map((answer) => {
          return (
            <FormControlLabel
              style={{
                marginBottom: 12,
                fontSize: 12,
              }}
              onChange={(event, value) => handleAnswer(answer)}
              checked={selected?._id === answer._id ? true : false}
              control={
                <Checkbox
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 32 },
                    color: "#BDBCDB",
                    borderRadius: 12,
                    "&.Mui-checked": {
                      color: "#213669",
                      borderRadius: 12,
                    },
                  }}
                />
              }
              label={answer.text}
            />
          );
        })}
      </div>
    </div>
  );
};
