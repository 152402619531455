import React, { useMemo } from "react";
import { IcHeartRed, IcHeartFilled } from "assets";
import { useTranslation } from "react-i18next";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { CONFIGS } from "utils/configs";
import { useCallback } from "react";

export /**
 *
 *
 * @return {*}
 */
const BookCard = inject(
  "authStore",
  "bookStore"
)(
  observer(
    ({
      book,
      small = false,
      flex,
      full,
      authStore,
      bookStore,
      type,
      onClick,
    }) => {
      const navigate = useNavigate();
      const favorite = useMemo(() => {
        return (
          authStore.profile?.favorites?.filter((fav) => {
            return fav._id === book?._id;
          }).length > 0
        );
      }, [authStore.profile?.favorites, book?._id]);

      const { t } = useTranslation();

      const handleFavorite = useCallback(
        (e) => {
          e.stopPropagation();
          authStore.postFavorite(book?._id);
        },
        [authStore, book?._id]
      );

      const handleBook = useCallback(() => {
        if (type === "test") {
          onClick(book);
        } else {
          navigate(`/catalog/${book?._id}`);
        }
      }, [book, navigate, onClick, type]);

      if (book === null) {
        return null;
      }

      if (small) {
        return (
          <div onClick={handleBook} className="book-card-wrap small">
            <div className="flex justify-end">
              <button onClick={handleFavorite}>
                <img
                  className="ic-favorite"
                  src={favorite ? IcHeartFilled : IcHeartRed}
                  alt="book-fav"
                />
              </button>
            </div>
            <div className="flex items-center justify-center mb-10">
              <img
                className="book-card-image"
                src={`${CONFIGS.BASE_API}${book?.image}`}
                alt="book-image"
              />
            </div>
            <p className="book-card-autor">
              <span>{t("autor")}:</span> {book?.author?.name}{" "}
              {book.author?.surname}
            </p>
            <h4>{book?.name}</h4>
            <p className="book-card-price">{book.price} ₸</p>
          </div>
        );
      }

      return (
        <div onClick={handleBook} className={"flex flex-1"}>
          <div
            className={`book-card-wrap ${full && "big"} ${
              flex && "full-width"
            }`}
          >
            <div className="flex justify-end">
              <button onClick={handleFavorite}>
                <img
                  className="ic-favorite"
                  src={favorite ? IcHeartFilled : IcHeartRed}
                  alt="book-fav"
                />
              </button>
            </div>
            <div className="flex items-center justify-center mb-10">
              <img
                className="book-card-image"
                src={`${CONFIGS.BASE_API}${book?.image}`}
                alt="book-image"
              />
            </div>
            <p className="book-card-autor">
              <span>{t("autor")}:</span> {book?.author?.name}{" "}
              {book.author?.surname}
            </p>
            <h4>{book?.name}</h4>
            <p className="book-card-price">{book?.price} ₸</p>
          </div>
        </div>
      );
    }
  )
);
