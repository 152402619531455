import { IcCloseMenu } from "assets";
import React, { useCallback, useMemo, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IcFbBlack, IcVkBlack, IcInstagramBlack } from "assets";
import { LanguageSelect } from "./LanguageSelect";
import { inject, observer } from "mobx-react";
import privatePolicy from "assets/privatePolicy.pdf?url";
import paymentPolicy from "assets/paymentPolicy.pdf?url";

export const SideBar = inject(
  "authStore",
  "bookStore"
)(
  observer(
    forwardRef((props, ref) => {
      const { authStore } = props;
      const { t } = useTranslation();
      let navigate = useNavigate();
      const { open, onClose, onType, onAuthModal } = props;

      const isFB = useMemo(() => {
        return props.bookStore.social.find((soc) => soc.name === "fb");
      }, [props.bookStore.social]);
      const isInst = useMemo(() => {
        return props.bookStore.social.find((soc) => soc.name === "inst");
      }, [props.bookStore.social]);

      const isVk = useMemo(() => {
        return props.bookStore.social.find((soc) => soc.name === "vk");
      }, [props.bookStore.social]);

      const handleAboutUs = useCallback(() => {
        const element = document.getElementById("about-us");
        onClose();
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, [onClose]);

      return (
        <div
          ref={ref}
          className={`sm:flex md:hidden  xl:hidden sidebar ${
            !open && "sidebar-closed"
          }`}
        >
          <div className="flex flex-1  flex-col">
            <div className="flex flex-col items-end">
              <button onClick={onClose}>
                <img className="ic-close-menu" src={IcCloseMenu} />
              </button>
            </div>
            <div className="sidebar-navigation">
              <Link onClick={onClose} to="/">
                <p className="sidebar-item">{t("main")}</p>
              </Link>
              <Link onClick={onClose} to="/catalog">
                <p className="sidebar-item">{t("catalog")}</p>
              </Link>
              <Link onClick={handleAboutUs} to="/">
                <p className="sidebar-item">{t("aboutProject")}</p>
              </Link>
              <Link target="_blank" to={privatePolicy}>
                <p className="sidebar-item">{t("privatePolicy")}</p>
              </Link>
              <Link target="_blank" to={paymentPolicy}>
                <p className="sidebar-item">{t("paymentPolicy")}</p>
              </Link>
              {authStore.isAuthenticated ? (
                <>
                  <Link onClick={onClose} to="/profile?cat=favorite">
                    <p className="sidebar-item">{t("favorites")}</p>
                  </Link>
                  <Link onClick={onClose} to="/profile">
                    <p className="sidebar-item">{t("profile")}</p>
                  </Link>
                </>
              ) : null}
            </div>
            <div className="flex flex-1 items-center flex-col justify-end">
              <div>
                <LanguageSelect />
              </div>
              {authStore.isAuthenticated ? (
                <div className="flex flex-col w-full mb-10">
                  <button
                    onClick={() => {
                      authStore.logout();
                      navigate("/");
                    }}
                    className="default-button sidebar-btn white border"
                  >
                    <p>{t("logout")}</p>
                  </button>
                </div>
              ) : (
                <div className="flex flex-col w-full mb-10">
                  <button
                    onClick={() => {
                      onType("signUp");
                      onAuthModal();
                      onClose();
                    }}
                    className="default-button sidebar-btn"
                  >
                    <p>{t("register")}</p>
                  </button>
                  <button
                    onClick={() => {
                      onType("signIn");
                      onAuthModal();
                      onClose();
                    }}
                    className="default-button sidebar-btn white border"
                  >
                    <p>{t("signIn")}</p>
                  </button>
                </div>
              )}

              <div
                style={{
                  gap: 12,
                }}
                className="flex"
              >
                {isInst && (
                  <Link>
                    <img className="ic-social" src={IcInstagramBlack} />
                  </Link>
                )}

                {isFB && (
                  <Link>
                    <img className="ic-social" src={IcFbBlack} />
                  </Link>
                )}

                {isVk && (
                  <Link>
                    <img className="ic-social" src={IcVkBlack} />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    })
  )
);
