import { Outlet } from "react-router-dom";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { SideBar } from "components/Sidebar";
import { Authorization } from "components/Authorization";
import { useCallback, useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer, inject } from "mobx-react";

export const Layout = inject("bookStore")(
  observer((props) => {
    const { bookStore } = props;
    const [shade, setShade] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [auth, setAuth] = useState(false);
    const [type, setType] = useState("");
    const sidebarRef = useRef(null);

    const handleToggleSidebar = () => {
      setShade((shade) => !shade);
      setSidebar((sidebar) => !sidebar);
    };

    const handleAuthModalToggle = () => {
      setAuth(true);
      setShade(true);
    };

    const handleType = (type) => {
      setType(type);
    };

    const handleCloseAuth = useCallback(() => {
      setAuth(false);
      setShade(false);
      bookStore.handleModal(false);
    }, [bookStore]);

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (sidebar) {
          setSidebar(false);
          setShade(false);
        }
      }
    };

    // Attach and detach event listener
    useEffect(() => {
      const events = ['mousedown', 'touchstart'];
      if (sidebar) {
        events.forEach(event => document.addEventListener(event, handleClickOutside));
      } else {
        events.forEach(event => document.removeEventListener(event, handleClickOutside));
      }

      return () => {
        events.forEach(event => document.removeEventListener(event, handleClickOutside));
      };
    }, [sidebar]);

    useEffect(() => {
      if (bookStore.showModal) {
        handleAuthModalToggle();
        handleType(bookStore.modalType);
      } else {
        handleCloseAuth();
      }
    }, [bookStore.modalType, bookStore.showModal]);

    return (
      <div className="">
        {/* <div className={`shade-wrap ${shade && "shade-active"}`}></div> */}
        <SideBar
          ref={sidebarRef}
          onAuthModal={handleAuthModalToggle}
          onType={handleType}
          onClose={handleToggleSidebar}
          open={sidebar}
        />
        <header>
          <Header
            onAuth={handleAuthModalToggle}
            onType={handleType}
            onSidebar={handleToggleSidebar}
          />
        </header>
        <div className="container mx-auto layout-container">
          <Outlet />
        </div>
        <footer>
          <Footer />
        </footer>
        {auth && (
          <Authorization
            onClose={() => handleCloseAuth()}
            open={auth}
            type={type}
          />
        )}

        <ToastContainer
          style={{
            fontSize: 22,
          }}
        />
      </div>
    );
  })
);
