import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Helmet } from "react-helmet";
import { IcLogo } from "assets";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { App } from "root/App.jsx";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "mobx-react";
import rootStore from "store";
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://c1566d817624fc6b2cbe7b3c91a55332@o4506071572021248.ingest.sentry.io/4506071572873216",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Helmet title="Senimen">
      <meta charSet="utf-8" />
      {/* <title>MyBook</title> */}
      <link id="favicon" rel="icon" href={IcLogo} type="image/x-icon" />
      <link rel="canonical" href="http://www.learnbestcoding.com" />
     
    </Helmet>
    <GoogleOAuthProvider clientId="328582712837-kde6v5sch7moncr2e37sd31v5h9c8ish.apps.googleusercontent.com">
      <Provider {...rootStore}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
