import React from "react";
import "styles/Components.css";
import StarRatings from "react-star-ratings";

export const Review = (props) => {
  const { review } = props;
  return (
    <div className="review-wrap">
      <div className="flex items-center mb-1 justify-between">
        <div className="flex items-center">
          <div className="review-avatar-wrap">
            {review.user?.image?.length > 0 ? (
              <img src={review.user?.image} />
            ) : (
              <p>{review.user?.fio?.substring(0, 1)}</p>
            )}
          </div>

          <h4 className="review-username">{review.user?.fio}</h4>
        </div>
        <StarRatings
          rating={Number(review.rating || 0)}
          starSpacing="2px"
          starDimension="14"
          starRatedColor="#F3BF07"
        />
      </div>
      <div>
        <p className="review-text">{review.text}</p>
      </div>
    </div>
  );
};
