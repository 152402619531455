import { BookCard } from "components/BookCard";
import { MenuNav } from "components/MenuNav";
import { FilterItem } from "components/FilterItem";

import { useDebouncedCallback } from "use-debounce";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterSidebar } from "components/FilterSidebar";
import { BookGrid } from "components/BookGrid";
import { inject, observer } from "mobx-react";

export const Catalog = inject("bookStore")(
  observer((props) => {
    const { bookStore } = props;
    const { books } = bookStore;

    const { t } = useTranslation();
    const [category, setCategory] = useState([]);
    const [author, setAuthor] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);

    const [routes, setRoutes] = useState([]);
    const [sidebar, setSidebar] = useState(false);

    useEffect(() => {
      const newRoutes = [
        {
          name: t("main"),
          to: "/",
        },
        {
          name: t("catalog"),
          to: "/catalog",
        },
      ];
      setRoutes(newRoutes);
    }, []);

    useEffect(() => {
      // props.bookStore.fetchAll();
    }, []);

    const handleScroll = () => {
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + document.documentElement.scrollTop) >
          10 ||
        isLoading
      ) {
        return;
      }
      handleSearch();
    };

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, [isLoading]);

    useEffect(() => {
      window.scrollTo(0, 100);
    }, []);

    const handleToggleSidebar = () => {
      setSidebar((sidebar) => !sidebar);
    };

    const handleCategory = (item) => {
      const indexOf = category.indexOf(item);
      if (indexOf < 0) {
        const ss = category;
        ss.push(item);
        setCategory(ss);
      } else {
        setCategory((categories) =>
          categories.filter((a) => a._id !== item._id)
        );
      }
    };

    const handleAuthor = (item) => {
      const indexOf = author.indexOf(item);
      if (indexOf < 0) {
        const ss = author;
        ss.push(item);
        setAuthor(ss);
      } else {
        setAuthor((authors) => authors.filter((a) => a._id !== item._id));
      }
    };

    const handleSearch = async () => {
      setIsLoading(true);
      const nA = author.map((a) => a._id);
      await bookStore.fetchBooks(category, nA, page);
      setPage((prevPage) => prevPage + 1);
      setIsLoading(false);
    };

    useEffect(() => {
      bookStore.fetchAutors();
      bookStore.fetchCategories();
      bookStore.fetchSocial();
      // handleSearch();
    }, []);

    return (
      <div className="section-padding">
        <FilterSidebar
          categories={bookStore.categories}
          authors={bookStore.authors}
          onCategories={handleCategory}
          onAuthor={handleAuthor}
          open={sidebar}
          onSearch={handleSearch}
          onClose={handleToggleSidebar}
        />
        <div className="hidden xl:block mb-10">
          <MenuNav routes={routes} />
        </div>
        <section>
          <h2 className="grip-title mb-4 xl:mb-16">{t("catalog")}</h2>
          <div className="block xl:hidden mb-8">
            <button onClick={handleToggleSidebar} className="default-button ">
              <p className="filter">{t("filter")}</p>
            </button>
          </div>
          <div className="hidden xl:grid  grid-cols-4 gap-32">
            <div className="">
              <div className="mb-16">
                <h4 className="filter-title mb-8">{t("genre")}</h4>
                {bookStore.categories.map((genre) => {
                  return <FilterItem onClick={handleCategory} item={genre} />;
                }, [])}
              </div>
              <div>
                <h4 className="filter-title mb-8">{t("autor")}</h4>
                {bookStore.authors.map((genre) => {
                  return <FilterItem onClick={handleAuthor} item={genre} />;
                }, [])}
              </div>
              <div className="flex">
                <button
                  onClick={handleSearch}
                  className="default-button big-padding mt-20 "
                >
                  <p>{t("apply")}</p>
                </button>
              </div>
            </div>

            <BookGrid items={books} />
          </div>
          <div className="grid xl:hidden grid-cols-2 gap-10">
            {books.map((book) => {
              return <BookCard flex book={book} />;
            })}
          </div>
        </section>
      </div>
    );
  })
);
