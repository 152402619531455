export function compareCFIInRange(start, end, myCFIcode) {
  if (start && end && myCFIcode) {
    const startSegments = start?.split("/").slice(1); // Removing the first empty segment
    const endSegments = end.split("/").slice(1);
    const myCFISegments = myCFIcode.split("/").slice(1);

    let index = 0;
    while (index < startSegments.length && index < endSegments.length) {
      const startSegment = parseInt(startSegments[index]);
      const endSegment = parseInt(endSegments[index]);
      const myCFISegment = parseInt(myCFISegments[index]);

      if (myCFISegment < startSegment || myCFISegment > endSegment) {
        return false; // myCFIcode is outside the range
      } else if (myCFISegment > startSegment && myCFISegment < endSegment) {
        return true; // myCFIcode is within the range
      }

      index++;
    }

    // Compare fragment identifiers if the rest of the segments are the same
    const startFragment = parseInt(
      startSegments[startSegments.length - 1]?.split(":")[1]
    );
    const endFragment = parseInt(
      endSegments[endSegments.length - 1]?.split(":")[1]
    );
    const myCFIFragment = parseInt(
      myCFISegments[myCFISegments.length - 1]?.split(":")[1]
    );

    return myCFIFragment >= startFragment && myCFIFragment <= endFragment;
  }
}
