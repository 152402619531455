import CryptoJS from "crypto-js";
import { CONFIGS } from "./configs";

export const encrypt = (string) => {
  return CryptoJS.AES.encrypt(string, CONFIGS.BOOK_ENC).toString();
};


export function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
