import axios from 'axios';
import { CONFIGS } from './configs';

const Axios = axios.create({
	baseURL: CONFIGS.BASE_API,
});

Axios.interceptors.request.use((data) => {
	return data;
});
Axios.interceptors.response.use((res) => {
	return res;
});

const configureAxios = (accessToken) => {
	Axios.defaults.baseURL = CONFIGS.BASE_API;
	Axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
	Axios.defaults.headers.post['Content-Type'] = 'application/json';
};

export { configureAxios, Axios };
