import React from "react";
import { TestQuestion } from "components/TestQuestion";

const questions = [
  {
    name: "Что можно делать обучающемуся в кабинете информатики только с разрешения учителя?",
    type: "select",
  },
  {
    name: "Что можно делать обучающемуся в кабинете информатики только с разрешения учителя?",
    type: "counter",
  },
  {
    name: "Что можно делать обучающемуся в кабинете информатики только с разрешения учителя?",
    type: "text",
  },
];

export const Test = () => {
  return (
    <div className="test-wrap">
      <div className="test-container container mx-auto">
        <h4>Тест 1. Глава 1</h4>
        <p className="desc-r">Правильных ответов может быть несколько</p>
        <div className="flex flex-col justify-between flex-1">
          {questions.map((q, i) => {
            return <TestQuestion index={i + 1} question={q} />;
          })}
        </div>
      </div>
    </div>
  );
};
