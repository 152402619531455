import { Checkbox } from "@mui/material";
import { inject, observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IcEyeClose, IcEyeOpen } from "assets";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const Settings = inject("authStore")(
  observer(({ user, authStore }) => {
    const { t } = useTranslation();
    const [form, setForm] = useState({
      fio: "",
      email: "",
      password: "",
      passwordConfirm: "",
    });
    const [valid, setValid] = useState({
      fio: true,
      email: true,
      password: true,
      passwordConfirm: true,
    });

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordCVisible, setPasswordCVisible] = useState(false);

    const allChecks = useMemo(() => {
      return Object.values(valid).every((k) => k === true);
    }, [valid]);

    useEffect(() => {
      setForm({
        fio: user.fio,
        email: user.email,
      });
    }, []);

    const validatePassworrd = (password) => {
      return String(password).match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*-=]{6,20}$/);
    };

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleChange = (event) => {
      setForm({
        ...form,
        [event.target.id]: event.target.value,
      });
      // eslint-disable-next-line default-case
      switch (event.target.id) {
        case "fio": {
          const v = event.target.value.length > 3;
          setValid({
            ...valid,
            [event.target.id]: v,
          });
          return;
        }
        case "email": {
          const v = validateEmail(event.target.value);
          setValid({
            ...valid,
            [event.target.id]: v,
          });
          return;
        }
        case "password": {
          const v = validatePassworrd(event.target.value);
          setValid({
            ...valid,
            [event.target.id]: v ? true : false,
          });
          if (event.target.value !== form.passwordConfirm) {
            setValid({
              ...valid,
              passwordConfirm: false,
            });
          } else {
            setValid({
              ...valid,
              passwordConfirm: true,
            });
          }

          return;
        }
        case "passwordConfirm": {
          const v = form.password === event.target.value;
          setValid({
            ...valid,
            [event.target.id]: v,
          });

          if (event.target.value !== form.password) {
            setValid({
              ...valid,
              passwordConfirm: false,
            });
          }

          return;
        }
      }
    };

    const renderField = (name, type, options) => {
      return (
        <div className="polca">
          <label
            className={`input-field-wrap ${valid[name] === false && "error"}`}
          >
            <p>{t(name)}</p>
            <div className="flex ">
              <input
                id={name}
                value={form[name]}
                className="flex flex-1"
                type={options?.visible ? "text" : type || "text"}
                placeholder={t(name)}
                onChange={(e) => handleChange(e)}
              />
              {name.includes("password") && (
                <div onClick={options.onClick}>
                  <img
                    style={{
                      width: 20,
                      height: 20,
                      cursor: "pointer",
                    }}
                    src={options?.visible ? IcEyeOpen : IcEyeClose}
                  />
                </div>
              )}
            </div>
          </label>
          <p className="error-text">{!valid[name] && t(`error_${name}`)}</p>
        </div>
      );
    };

    const handleUpdate = useCallback(() => {
      if (allChecks) {
        let data = {
          fio: form.fio,
          email: form.email,
        };
        if (form.password) {
          data.password = form.password;
        }
        if (form.password !== form.passwordConfirm) {
          return null;
        }
        setForm((prev) => ({
          ...prev,
          password: "",
          passwordConfirm: "",
        }));
        authStore.updateProfile(data);
      }
    }, [allChecks, authStore, form]);

    const handleDelete = useCallback(() => {
      authStore.deleteAccount();
    }, [authStore]);

    return (
      <div className="settings-wrap">
        <div className="mb-0">
          <h3 className="settings-title">{t("myData")}</h3>
          {renderField("fio", "text")}
          {renderField("email", "email")}
        </div>
        <div className="flex  mb-8 pl-8">
          <p className="text-xl">
            {user.email_verified
              ? "Почта подтверждена"
              : "Почта не подтверждена"}
          </p>
        </div>
        <div>
          <h3 className="settings-title"> {t("password")}</h3>
          {renderField("password", "password", {
            maxLength: 20,
            visible: passwordVisible,
            onClick: () => setPasswordVisible((prev) => !prev),
          })}
          {renderField("passwordConfirm", "password", {
            maxLength: 20,
            visible: passwordCVisible,
            onClick: () => setPasswordCVisible((prev) => !prev),
          })}
        </div>

        <div className={"flex flex-row justify-end gap-4"}>
          <button
            onClick={handleClickOpen}
            className="default-button red px-st"
          >
            <p>{t("deleteAccount")}</p>
          </button>
          <button
            disabled={!allChecks}
            onClick={handleUpdate}
            className="default-button"
          >
            <p>{t("changeData")}</p>
          </button>
        </div>
        <div className="flex mt-4"></div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title font-bold text-xl"
          >
            Вы уверены что хотите удалить аккаунт?
          </DialogTitle>

          <DialogActions>
            <Button onClick={handleClose}>
              <span className="text-xl">Нет</span>
            </Button>
            <Button variant="outlined" color="error" onClick={handleDelete}>
              <span className="text-xl">Да</span>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  })
);
