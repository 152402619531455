import React from "react";
import { useSnapCarousel } from "react-snap-carousel";
import { BookCard } from "./BookCard";
import { NotFound } from "./NotFound";

const Carousel = (props) => {
  const { scrollRef, pages, activePageIndex, next, prev, goTo } =
    useSnapCarousel();
  return (
    <div
      className="
    "
    >
      <ul
        ref={scrollRef}
        className="carousel-wrap"
       
      >
        {props.items.length > 0 ? (
          props.items.map((book, i) => <BookCard small book={book} />)
        ) : (
          <NotFound />
        )}
      </ul>
    </div>
  );
};

export default Carousel;
