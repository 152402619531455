import React from "react";

export const SocialButton = (props) => {
  return (
    <button onClick={props.onClick} className="default-button start mb-5">
      <img src={props.image} />
      <p>{props.text}</p>
    </button>
  );
};
