import React, { useCallback, useMemo, useState } from "react";
import { BookCard } from "components/BookCard";
import { NotFound } from "./NotFound";
import { TestModal } from "./TestModal";
import { Box, Button, Modal } from "@mui/material";
import { TestResult } from "./TestResult";

const style = {
  position: "absolute",
  bgcolor: "#EFF3F7",
  boxShadow: 24,
  height: "100vh",
  pt: 2,
  px: 4,
};

export const BookGrid = (props) => {
  const { items, type } = props;
  const [modal, setModal] = useState(false);
  const [test, setTest] = useState(null);
  const [testShown, setTestShown] = useState(false);
  const [testList, setTestList] = useState([]);

  const handleBook = useCallback((tests) => {
    setTestShown(true);
    setTestList(tests);
  }, []);

  const asd = useMemo(() => {
    const sd = new Map();
    const list = items?.map((i) => sd.set(i?.book?._id, i));
    return type ? Array.from(sd.values()) : items;
  }, [items, type]);

  return (
    <>
      {testShown ? (
        <>
          {items
            .filter((i) => i?.book?._id === test?.book?._id)
            .map((t) => {
              return t.tests?.map((test) => {
                if (test.test)
                  return (
                    <div
                      onClick={() => {
                        setTest(test);
                        setModal(true);
                      }}
                      style={{
                        background: "var(--Grey-BG, #EFF3F7)",
                        padding: "8px 20px",
                        borderRadius: "10px",
                        cursor: "pointer",
                        marginBottom: 16,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          fontWeight: "400",
                        }}
                      >
                        {test?.test?.name}
                      </span>
                    </div>
                  );
              });
            })}
        </>
      ) : (
        <div className="col-span-3 grid  grid-cols-2 xl:grid-cols-3 gap-10 xl:gap-20">
          {asd?.length === 0 && <NotFound />}
          {asd?.length > 0 &&
            asd?.map((book) => {
              return (
                <BookCard
                  flex
                  full
                  type={type}
                  onClick={() => {
                    setTest(book);
                    handleBook(book.tests);
                  }}
                  book={typeof book.book === "object" ? book.book : book}
                />
              );
            })}
        </div>
      )}

      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{
          paddingBottom: 0,
        }}
      >
        <Box
          sx={{
            ...style,
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            padding: 1,
            overflow: "scroll",
            height: "100vh",
          }}
        >
          <TestResult
            onClose={() => {
              setModal(false);
              setTestShown(false);
            }}
            test={test?.test}
            testResult={test?.result}
            questions={test?.test?.questions}
          />
        </Box>
      </Modal>
    </>
  );
};
